export const prescriptionStatus = [
    { id: "", name: "Tất cả" },
    { id: "COMPLETED", name: "Hoàn thành", className: "call-type call-type-completed" },
    { id: "ASSIGNING", name: "Đã nhận đơn", className: "call-type call-type-assigned" },
    { id: "CANCELED", name: "Đã hủy", className: "call-type call-type-canceled" },
    { id: "CREATED", name: "Đang tìm nhà thuốc", className: "call-type call-type-created" },
    { id: "REFILLED", name: "Chờ xác nhận", className: "call-type call-type-refilled" }
];

export const prescriptionEvents = {
    ORDERED: 'Nhà thuốc xác nhận đơn',
    ASSIGNING_DRIVER: 'Đang tìm tài xế',
    ON_GOING: 'Tài xế đang lấy hàng',
    PICKED_UP: 'Đang vận chuyển',
    SHIPPED: 'Đang giao hàng',
    // CANCELED: 'Đơn hàng đã bị huỷ',
    DELIVERED: 'Giao hàng thành công',
    EXPIRED: 'Không tìm thấy tài xế',
    REJECTED: 'Tài xế từ chối nhận đơn',
    DELIVERY_FAILED: 'Giao hàng thất bại',
    RETURNED: 'Hoàn hàng thành công'
}