import { Select } from "antd";
import { uniqBy } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { nonAccentVietnamese } from "@utils/common";
import styled from "styled-components";

function SelectLazy({
  name,
  nameOption = (item) => item?.name || "",
  valueOption = (item) => item?.value || "",
  mode,
  defaultValue = mode === "multiple" ? [] : undefined,
  placeholder = "Chọn",
  pageDefault = 0,
  sizeDefault = 20,
  get = () => { },
  set,
  optionAll = false,
  allowClear = false,
  className,
  disabled = false,
  optionsDefault = [],
  searchName = name,
}) {
  const [options, setOptions] = useState([...optionsDefault]);
  const [page, setPage] = useState(pageDefault);
  const [total, setTotal] = useState(0);
  const [value, setValue] = useState(defaultValue);
  const [loading, setLoading] = useState(false);

  const timeoutRef = useRef();

  useEffect(() => {
    getOptions();
  }, [page]);
  useEffect(() => {
    setValue(defaultValue);
    setOptions([...options, ...optionsDefault])
  }, [defaultValue]);

  const onScroll = (event) => {
    var target = event.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      if (page < (pageDefault === 0 ? total - 1 : total)) {
        setPage(page + 1);
      }
    }
  };

  const handleSearch = (value) => {
    let v = value ? value.trim() : null;
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      if (v || v == "") {
        getOptions({ page: pageDefault, size: sizeDefault, [searchName]: v });
      }
    }, 300);
  };

  const getOptions = async (params = {}) => {
    try {
      setLoading(true);
      const { data, totalPages } = await get({
        page,
        size: sizeDefault,
        ...params,
      });
      setLoading(false);
      let newOptions = uniqBy([...options, ...data], (item) => {
        return item.id;
      });
      setOptions(newOptions);
      setTotal(totalPages);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleOnChange = (values, select) => {
    setValue(values);
    set(name, select?.optionValue);
  };

  const handleFilter = (input, option) => {
    return nonAccentVietnamese((option.children || "").toLowerCase()).indexOf(nonAccentVietnamese(input || "").trim().toLowerCase()) >= 0;
  };

  return (
    <div>
      <SelectCustom
        style={{ width: "100%" }}
        onChange={set ? handleOnChange : undefined}
        showSearch={true}
        allowClear={allowClear}
        defaultValue={defaultValue}
        value={value}
        filterOption={handleFilter}
        onPopupScroll={onScroll}
        placeholder={placeholder}
        onSearch={handleSearch}
        mode={mode}
        className={className}
        disabled={disabled}
        dropdownClassName="select-infinity"
      >
        {optionAll && (
          <Select.Option key="tat-ca" value="">
            Tất cả
          </Select.Option>
        )}

        {options?.map((option, index) => (
          <Select.Option key={option?.id || index} value={valueOption(option)} name={name} optionValue={option}>
            {nameOption(option)}
          </Select.Option>
        ))}
        {loading && (
          <Select.Option key="loading" disabled>
            Loading...
          </Select.Option>
        )}
      </SelectCustom>
    </div>
  );
}

export default SelectLazy;

const SelectCustom = styled(Select).attrs(props => ({
  suffixIcon: !props?.loading ? (
      <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.2946 0.294459C10.9053 -0.0948134 10.2743 -0.0951574 9.88462 0.293691L6.70634 3.46505C6.31599 3.85454 5.68401 3.85454 5.29366 3.46505L2.11538 0.29369C1.72569 -0.0951576 1.09466 -0.0948134 0.705384 0.294459C0.315811 0.684032 0.315811 1.31565 0.705384 1.70523L5.29289 6.29274C5.68342 6.68326 6.31658 6.68326 6.70711 6.29274L11.2946 1.70523C11.6842 1.31565 11.6842 0.684032 11.2946 0.294459Z" fill="#101011"/>
      </svg>    
  ) : undefined,
  size: 'default'
}))`
`