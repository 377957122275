import React, { useState, useEffect } from 'react';

const ItemNotificationIcon = () => {
  const [countNotification, setCountNotification] = useState(0);

  useEffect(() => {
    const callBack = (event) => {
      setCountNotification(event?.detail?.count)
    }
    document.addEventListener('countNotification', callBack)
    return () => {
      document.removeEventListener('countNotification', callBack)
    }
  }, [])

  return (
    <>
      {!!countNotification &&
        <div className="badge">
          {+countNotification > 99 ? "99+" : countNotification}
        </div>
      }
    </>
  );
}

export default React.memo(ItemNotificationIcon);
