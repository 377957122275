import HeadingPage from "../HeadingPage";
import React from "react";
import notPermisstion from "../../../../resources/images/notPermisstion.png";
import { NotPermisstionWrapper } from "./styled";

function NotPermisstion({ heading }) {
  return (
    <NotPermisstionWrapper>
      <div class="p-head">
        <HeadingPage content={heading} />
      </div>
      <div class="p-content">
        <img src={notPermisstion} alt="Not permisstion" />
        <p>
          {" "}
          <b>Bạn không có quyền truy cập chức năng này.</b>
          Vui lòng liên hệ admin để được cấp quyền.
        </p>
      </div>
    </NotPermisstionWrapper>
  );
}

export default NotPermisstion;
