import React from 'react';
import { Input } from "antd";
import styled from "styled-components";

export const InputWrapper = styled(Input).attrs(props => ({
  prefix: !props?.loading ? (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_20871_165289)">
    <path d="M13.1583 11.9083H12.5L12.1583 11.5667C13.2 10.4 13.8333 8.85833 13.8333 7.16667C13.8333 3.48333 10.85 0.5 7.16667 0.5C3.48333 0.5 0.5 3.48333 0.5 7.16667C0.5 10.85 3.48333 13.8333 7.16667 13.8333C8.85833 13.8333 10.4 13.2 11.575 12.1583L11.9083 12.5V13.1583L14.2583 15.5L15.5 14.2583L13.1583 11.9083ZM7.16667 12.1667C4.40833 12.1667 2.16667 9.925 2.16667 7.16667C2.16667 4.40833 4.40833 2.16667 7.16667 2.16667C9.925 2.16667 12.1667 4.40833 12.1667 7.16667C12.1667 9.925 9.925 12.1667 7.16667 12.1667Z" fill="white"/>
    <path d="M13.1583 11.9083H12.5L12.1583 11.5667C13.2 10.4 13.8333 8.85833 13.8333 7.16667C13.8333 3.48333 10.85 0.5 7.16667 0.5C3.48333 0.5 0.5 3.48333 0.5 7.16667C0.5 10.85 3.48333 13.8333 7.16667 13.8333C8.85833 13.8333 10.4 13.2 11.575 12.1583L11.9083 12.5V13.1583L14.2583 15.5L15.5 14.2583L13.1583 11.9083ZM7.16667 12.1667C4.40833 12.1667 2.16667 9.925 2.16667 7.16667C2.16667 4.40833 4.40833 2.16667 7.16667 2.16667C9.925 2.16667 12.1667 4.40833 12.1667 7.16667C12.1667 9.925 9.925 12.1667 7.16667 12.1667Z" fill="#172B4D" fill-opacity="0.5"/>
    </g>
    <defs>
    <clipPath id="clip0_20871_165289">
    <rect width="16" height="16" fill="white"/>
    </clipPath>
    </defs>
    </svg>    
  ) : undefined,
}))`
  flex: 1 1;
  border-radius: 4px;
  border: 1px solid #DFE1E6;
  height: 32px;
  /* &:last-child {
    border-radius: 0 4px 4px 0 !important;
  } */
`;
