import client from "../utils/client-utils";
import stringUtils from "mainam-react-native-string-utils";
import constants from "../resources/strings";
import datacacheProvider from "./datacache-provider";
import clientUtils from "../utils/client-utils";

var md5 = require("md5");
export default {
  getByIds({ page = 0, size = 20, ids = [] }) {
    const url = `${constants.api.user.byIds}?page=${page}&size=${size}${ids ? `&ids=${ids.join(",")}` : ""
      }`;
    return clientUtils.requestApi("get", url).then((res) => res);
  },
  importUser(file) {
    return clientUtils
      .uploadFile(constants.api.user.uploadUser, file)
      .then((res) => res);
  },
  getUserByPhones(body) {
    return clientUtils
      .requestApi("post", constants.api.user.userByPhones, body)
      .then((res) => res);
  },
  getAccountStorage() {
    var user = datacacheProvider.read(
      "",
      constants.key.storage.current_account
    );
    return user;
  },
  saveAccountStorage(account) {
    return datacacheProvider.save(
      "",
      constants.key.storage.current_account,
      account
    );
  },
  xx() {
    client.serverApi = "";
  },
  login(emailOrPhone, password) {
    let object = {
      emailOrPhone,
      password: md5(password),
      device: {
        token: global.accessToken,
        os: 3,
      },
    };
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("put", constants.api.user.login, object)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  logout(){
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("put", constants.api.user.logout, {})
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  search({
    page,
    size,
    name,
    phone,
    gender,
    active,
    specialistId,
    type,
    roleId,
    style,
    username,
    createFrom,
    createTo,
    vendorId,
  }) {
    let parameters =
      (page ? "?page=" + page : "?page=" + -1) +
      (size ? "&size=" + size : "&size=" + -1) +
      (name ? "&name=" + name : "") +
      (phone ? "&phone=" + phone : "") +
      (gender ? "&phone=" + gender : "") +
      (active ? "&active=" + active : "&active=" + -1) +
      (specialistId ? "&specialistId=" + specialistId : "&specialistId=" + -1) +
      (type ? "&type=" + type : "&type=" + -1) +
      (roleId ? "&roleId=" + roleId : "&roleId=" + -1) +
      (style ? "&style=" + style : "&style=" + -1) +
      (username ? "&username=" + username : "") +
      (createFrom ? "&createFrom=" + createFrom : "") +
      (createTo ? "&createTo=" + createTo : "") +
      (vendorId ? "&vendorId=" + vendorId : "");
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("get", constants.api.user.search + parameters)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  filter({
    page,
    size,
    name,
    phone,
    gender,
    active,
    specialistId,
    type,
    roleId,
    style,
    username,
    fromDate,
    toDate,
    vendorId,
    patientValue,
    locked
  }) {
    let parameters =
      (page ? "?page=" + (page - 1) : "?page=" + -1) +
      (size ? "&size=" + size : "&size=" + -1) +
      (name ? "&name=" + name : "") +
      (phone ? "&phone=" + phone : "") +
      (gender ? "&phone=" + gender : "") +
      (active ? "&active=" + active : "") +
      ((locked || locked === 0) ? "&locked=" + locked : "") +
      // (specialistId ? "&specialistId=" + specialistId : "&specialistId=" + -1) +
      // (type ? "&type=" + type : "&type=" + -1) +
      // (roleId ? "&roleId=" + roleId : "&roleId=" + -1) +
      // (style ? "&style=" + style : "&style=" + -1) +
      (username ? "&username=" + username : "") +
      (fromDate ? "&fromDate=" + fromDate : "") +
      (toDate ? "&toDate=" + toDate : "") +
      (patientValue ? "&patientValue=" + patientValue : "") +
      (vendorId ? "&vendorId=" + vendorId : "");
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("get", constants.api.user.filter + parameters)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  getUserInActive(
    page = 0,
    size = 999,
    gender,
    telephone,
    fullname,
    guardianname,
    active,
    guardiantelno
  ) {
    let parameters =
      (page || page == 0 ? "?page=" + page : "?page=" + -1) +
      (size ? "&size=" + size : "&size=" + -1) +
      (gender ? "&gender=" + gender : "") +
      (telephone ? "&telephone=" + telephone : "") +
      (fullname ? "&fullname=" + fullname : "") +
      (guardianname ? "&guardianname=" + guardianname : "") +
      (active ? "&active=" + active : "") +
      (guardiantelno ? "&guardiantelno=" + guardiantelno : "");
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("get", constants.api.user.getUserInActive + parameters, {})
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updatePassword(id, object) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("put", constants.api.user.updatePassword + "/" + id, object)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  create(object) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("post", constants.api.user.create, object)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  update(id, object) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("put", constants.api.user.update + "/" + id, object)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  block(id, object) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("put", constants.api.user.block + "/" + id, object)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  active(id, object) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("put", constants.api.user.active + "/" + id, object)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getDetail(id) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("get", constants.api.user.detail + "/" + id)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getProfileByUserId(id) {
    return new Promise((resolve, reject) => {
      clientUtils
      .requestApi("get", constants.api.user.getProfileByID.replace(":userId", id))
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async resetPassword(userId, password) {
    return await clientUtils.requestApiFix(
      "put",
      constants.api.user.resetPassword.replace(":userId", userId),
      {
        password: md5(password),
      }
    );
  },

  async activeUser(id) {
    return await clientUtils.requestApiFix(
      "post",
      constants.api.user.activeUser.replace(":id", id)
    );
  },
  setPasswordPatient(id) {
    // debugger
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("put", constants.api.user.set_password + id)
        .then((x) => {
          // debugger
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  validateToken(id) {
    return new Promise((resolve, reject) => {
      clientUtils
        .axios("get", constants.api.validateToken)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  createPatient(hospitalId, object) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("post", constants.api.user.create_patient.replace(":hospitalId", hospitalId), object)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updatePatient(hospitalId, medicalProfileId, object) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("put", constants.api.user.update_patient.replace(":hospitalId", hospitalId).replace(":medicalProfileId", medicalProfileId), object)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getDetailPatient(userId, hospitalId) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("get", constants.api.user.detail_patient.replace(":hospitalId", hospitalId).replace(":userId", userId))
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  lockAccount(id) {
    // debugger
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("put", constants.api.user.lock.replace(":id", id))
        .then((x) => {
          // debugger
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  unlockAccount(id) {
    // debugger
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("put", constants.api.user.unlock.replace(":id", id))
        .then((x) => {
          // debugger
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async smartSearchAdd(searchValue) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("get", constants.api.user.smart_search + `?value=${searchValue}`)
        .then((x) => {
          resolve(x);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  searchUserByYearOfBirthName(dateOfBirth, name, hospitalId) {
    return new Promise((resolve, reject) => {
      clientUtils
        .requestApi("get", constants.api.user.search_dateOfBirth.replace(":hospitalId", hospitalId) + `?name=${name}&dateOfBirth=${dateOfBirth}`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
};
