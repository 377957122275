import { API_ENDPOINT } from "@strings";
import axios from "axios";

// const server_url = "http://10.0.0.98:8000" ; //dev
const server_url = API_ENDPOINT.server_url; //test
window.server_url = server_url;
// const server_url = "https://api.produce.isofhcare.com"; //release
// const server_url = "http://34.95.91.81"; //stable

// const resource_url = "https://www.googleapis.com/download/storage/v1/b/isofh-care-dev/o/"; //dev
// const resource_url = 'https://storage.googleapis.com/isofh-care-dev/'; //test
const resource_url = API_ENDPOINT.resource_url; //test
// const resource_url = "https://www.googleapis.com/download/storage/v1/b/isofh-care-stable/o/"; //stable
// const resource_url = "https://www.googleapis.com/download/storage/v1/b/isofhcare-storage/o/"; //release

const httpClient = axios.create();
httpClient.defaults.timeout = 50000;

httpClient.interceptors.response.use(
    (response) => {
        if (response?.status === 401 || response?.data?.code === 401) {
            const columnSetting = localStorage.getItem("columnSetting");
            const columnLeadSetting = localStorage.getItem("columnLeadSetting");
            localStorage.clear();
            if (columnSetting) {
                localStorage.setItem('columnSetting', columnSetting)
            }
            if (columnLeadSetting) {
                localStorage.setItem('columnLeadSetting', columnLeadSetting)
            }
            if (!window.location.href.includes('/dang-nhap'));
            window.location.href = '/dang-nhap';
            return Promise.reject();
        }
        return response;
    },
    (error) => {
        if (error?.response?.status === 401) {
            const columnSetting = localStorage.getItem("columnSetting");
            const columnLeadSetting = localStorage.getItem("columnLeadSetting");
            localStorage.clear();
            if (columnSetting) {
                localStorage.setItem('columnSetting', columnSetting)
            }
            if (columnLeadSetting) {
                localStorage.setItem('columnLeadSetting', columnLeadSetting)
            }
            if (!window.location.href.includes('/dang-nhap'))
                window.location.href = '/dang-nhap';
        } else {
            try {
                if (error?.response?.data?.message)
                    error.message = error.response.data.message;
            } catch (error) { }
        }
        return Promise.reject(error);
    },
);

String.prototype.absoluteUrl =
    String.prototype.absolute ||
    function (defaultValue) {
        var _this = this.toString();
        if (_this == "") {
            if (defaultValue != undefined) return defaultValue;
            else return _this;
        }

        if (_this[0] == "/") _this = _this.substr(1);

        if (_this.indexOf("http") == 0 || _this.indexOf("blob") == 0) {
            return _this;
        }
        let _this2 = _this.toLowerCase();
        if (
            _this2.endsWith(".jpg") ||
            _this2.endsWith(".jpeg") ||
            _this2.endsWith(".png") ||
            _this2.endsWith(".gif")
        ) {
            let image = resource_url + encodeURIComponent(_this + "") + "?alt=media";
            // console.log(image);
            return image;
        }
        if (
            !_this2.endsWith(".jpg") ||
            !_this2.endsWith(".jpeg") ||
            !_this2.endsWith(".png") ||
            !_this2.endsWith(".gif")
        ) {
            return defaultValue;
        }
        // if(this.startsWith("user"))

        //     return
        return server_url + _this + "";
    };

let browser = (function () {
    var ua = navigator.userAgent,
        tem,
        M =
            ua.match(
                /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
            ) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return "IE " + (tem[1] || "");
    }
    if (M[1] === "Chrome") {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(" ");
})();

String.prototype.getServiceUrl =
    String.prototype.absolute ||
    function (defaultValue) {
        let _this = this ? this.toString() : "";
        if (_this == "")
            if (defaultValue != undefined) return defaultValue;
            else return _this;
        if (_this.indexOf("http") == 0 || _this.indexOf("blob") == 0) {
            return _this;
        }
        return server_url + _this;
    };

export default {
    // auth: "eyJhbGciOiJSUzI1NiJ9.eyJyb2xlIjoiaXNvZmhDYXJlIiwiY3JlYXRlZCI6MTU1MzA3MDc0Mzc4NiwidHlwZSI6MCwidXNlcklkIjo1NX0.k8B3Cm5M-22ckpKk3W1fhgHoHq7LGVdKIjhLZUl0abKES5nSCC5RhupsRXctTK6skQMvCZ8f-TuZGbDcNgdlsb_Kc0ogFmaPmGI4ao7MKrCb9nCr4fxztUN0ABWUERA1wnQNFljgVR9FIrNKnf2hx_aTHIrwS9Ol1JOaKJVnj83cK5vg2ExvN7ralb1yoyuHEZoODlDBVHCIxeG5X3oaJE6-BKfcafXau_cmYz-Ovg31VtZpu1lCffaOj2uLSefPBvqfL2d2U1sswiUrV95rankTjOomr31lP4xiCN71-7YX_6Hx7EraRFhmclmaOjGUWM83VB0fvY8hIEHiE8yB8w",
    auth: "",
    serverApi: server_url,
    response: {
        ok(data, message) {
            if (!message) message = "";
            return {
                success: true,
                data: data,
                message: message,
            };
        },
        noOk(message) {
            if (!message) message = "";
            return {
                success: false,
                message: message,
            };
        },
    },
    uploadFile(url, file) {
        const formData = new FormData();
        formData.append("file", file);
        // formData.append("files", file);
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: this.auth,
                // deviceType: "web",
                // deviceOs: browser
                // 'MobileMode':'vendorPortal'
            },
        };

        return axios.post(url.getServiceUrl(), formData, config);
    },

    requestAxios(url) {
        console.log("url: ", url);
        axios
            .get(url)
            .then((response) => {
                console.log(response.headers.entries(), "headerss");
            })
            .catch((error) => {
                console.log("error: ", error);
            });
    },

    axios(method, url, data) {
        return new Promise((resolve, reject) => {
            axios({
                method,
                url,
                headers: {
                    Authorization: this.auth,
                    "Accept-Language": "vi-VN,vi;",
                },
                data: data ? data : {},
            })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    requestApiNews(methodType, url, body, isPlain = false) {
        return new Promise((resolve, reject) => {
            // console.log("Request url " + url + " with token: " + this.auth);
            var dataBody = "";
            if (!body) body = {};
            dataBody = isPlain ? body : JSON.stringify(body);
            this.requestFetch(
                methodType,
                url && url.indexOf("http") == 0 ? url : url,
                {
                    // "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: this.auth,
                    "Accept-Language": "vi-VN,vi;",
                    // isAdmin: true
                    // deviceType: "web",
                    // deviceOs: browser,
                    // 'MobileMode': 'vendorPortal'
                },
                dataBody
            )
                .then((s) => {
                    resolve(s);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    requestApiLevelCard(methodType, url, body) {
        return new Promise((resolve, reject) => {
            // console.log("Request url " + url + " with token: " + this.auth);
            var dataBody = "";
            if (!body) body = {};
            dataBody = JSON.stringify(body);
            this.requestFetch(
                methodType,
                url && url.indexOf("http") == 0 ? url : url,
                {
                    // "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: this.auth,
                    "Accept-Language": "vi-VN,vi;",
                    // isAdmin: true
                    // deviceType: "web",
                    // deviceOs: browser,
                    // 'MobileMode': 'vendorPortal'
                },
                dataBody
            )
                .then((s) => {
                    resolve(s);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    requestApi(methodType, url, body) {
        return new Promise((resolve, reject) => {
            // console.log("Request url " + url + " with token: " + this.auth);
            var dataBody = "";
            if (!body) body = {};
            dataBody = JSON.stringify(body);
            this.requestFetch(
                methodType,
                url && url.indexOf("http") == 0 ? url : url,
                {
                    // "Access-Control-Allow-Origin": "*",
                    // "Access-Control-Allow-Origin": "*",
                    // "Access-Control-Allow-Headers": "*",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Accept-Language": "vi-VN,vi;",
                    Authorization: this.auth,
                    // ...body,
                    // isAdmin: true
                    // deviceType: "web",
                    // deviceOs: browser,
                    // 'MobileMode': 'vendorPortal'
                },
                dataBody,
            )
                .then((s) => {
                    resolve(s);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    requestApiHeader(methodType, url, body) {
        return new Promise((resolve, reject) => {
            // console.log("Request url " + url + " with token: " + this.auth);
            var dataBody = "";
            if (!body) body = {};
            dataBody = JSON.stringify(body);
            this.requestFetch(
                methodType,
                url && url.indexOf("http") == 0 ? url : url,
                {
                    // "Access-Control-Allow-Origin": "*",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: this.auth,
                    isAdmin: true,
                    "Accept-Language": "vi-VN,vi;"
                    // deviceType: "web",
                    // deviceOs: browser
                    // 'MobileMode':'vendorPortal'
                },
                dataBody
            )
                .then((s) => {
                    resolve(s);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    requestApiFix(methodType, url, body) {
        return new Promise((resolve, reject) => {
            // console.log("Request url " + url + " with token: " + this.auth);
            var dataBody = "";
            if (!body) body = {};
            dataBody = JSON.stringify(body);
            this.requestFetch(
                methodType,
                url && url.indexOf("http") == 0 ? url : url,
                {
                    // "Access-Control-Allow-Origin": "*",
                    // "Access-Control-Allow-Headers": "*",
                    // 'Access-Control-Allow-Credentials':true,
                    // "Access-Control-Allow-Methods":
                    //   "GET, POST, OPTIONS, PUT, PATCH, DELETE",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: this.auth,
                    "Accept-Language": "vi-VN,vi;"
                    // 'MobileMode': 'vendorPortal',
                    // 'deviceType': 'web',
                    // 'deviceOs': browser,
                },
                dataBody
            )
                .then((s) => {
                    resolve(s);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    requestWithHeaders(methodType, url, body, headers) {
        return new Promise((resolve, reject) => {
            // console.log("Request url " + url + " with token: " + this.auth);
            var dataBody = "";
            if (!body) body = {};
            dataBody = JSON.stringify(body);
            this.requestFetch(
                methodType,
                url && url.indexOf("http") == 0 ? url : url,
                {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Accept-Language": "vi-VN,vi;",
                    Authorization: this.auth,
                    ...headers,
                },
                dataBody
            )
                .then((s) => {
                    resolve(s);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    requestApiVi(methodType, url, body) {
        return new Promise((resolve, reject) => {
            // console.log("Request url " + url + " with token: " + this.auth);
            var dataBody = "";
            if (!body) body = {};
            dataBody = JSON.stringify(body);
            this.requestFetch(
                methodType,
                url && url.indexOf("http") == 0 ? url : url,
                {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.auth,
                    "Accept-Language": "vi-VN,vi;",
                    // deviceType: "web",
                    // deviceOs: browser
                    // 'Authorization': 'Bearer eyJhbGciOiJSUzI1NiJ9.eyJjcmVhdGVkIjoxNTUzNTcwMjQ1ODkzLCJpc3MiOiJpc29maENhcmUiLCJ2ZW5kb3JJZCI6MTMsInR5cGUiOjAsInVzZXJJZCI6MzY5OX0.Iddf9emNz2YkTt2J9Bfxi-0__St_8y3ndTQpMi3khCt58O0Zh71OP7ij7npl2unD-uXljxmDs8ywDoPUg7tQ8xsSYQNoYVT3esIudP6wKW2qoHV1euKopG9Ra3qH8_9WplmONucPGmzgauVXtdlPxlxdVc-ZZyPp9Ggvv6HxmZ5rcO3PootqG9CbawZjroKp4z2avobmmxKihC3UjJlMCeFSjRrSvZX5XWNhJdtJ2cX3xHyXdvLsR89UkokCckyLX4-I3nFQWsxNMx7RyHx7ymNcyWbaehZRBmZ6ksnnJaPp-YlHNDq9v1JwKqBTkEbiRXJbaOVhB0d4xrYFeJa7Fg',
                    // 'MobileMode':'vendorPortal'
                },
                dataBody
            )
                .then((s) => {
                    resolve(s);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    requestApiExcel(methodType, url, body, header, config = null) {
        return new Promise((resolve, reject) => {
            // console.log("Request url " + url + " with token: " + this.auth);
            var dataBody = "";
            if (!body) body = {};
            dataBody = JSON.stringify(body);
            this.requestFetch(
                methodType,
                url && url.indexOf("http") == 0 ? url : url,
                {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: this.auth,
                    "Accept-Language": "vi-VN,vi;",
                    isAdmin: true,
                    ...header
                },
                dataBody,
                config
            )
                .then((s) => {
                    resolve(s);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    requestFetch(methodType, url, headers, body, config) {
        let data = {
            methodType,
            url: url.getServiceUrl(),
            headers,
            body,
        };
        // console.log(JSON.stringify(data));
        return new Promise((resolve, reject) => {
            let promise1 = null;
            switch (methodType) {
                case "post":
                    promise1 = httpClient.post(url.getServiceUrl().toString(), body, {
                        headers,
                    });
                    break;
                case "patch":
                    promise1 = httpClient.patch(url.getServiceUrl().toString(), body, {
                        headers,
                    });
                    break;
                case "get":
                    let configAxios = null;
                    if (config) {
                        configAxios = { ...config, headers }
                    } else {
                        configAxios = { headers }
                    }
                    promise1 = httpClient.get(url.getServiceUrl().toString(), configAxios);
                    break;
                case "put":
                    promise1 = httpClient.put(url.getServiceUrl().toString(), body, {
                        headers,
                    });
                    break;
                case "delete":
                    promise1 = httpClient.delete(url.getServiceUrl().toString(), {
                        headers,
                    });
                    break;
            }

            promise1
                .then((json) => {
                    // console.log("DATA:  ==>", json);
                    // if (json.status != 200) {
                    //     reject(json);
                    // } else {
                    resolve(json.data);
                    // }
                })
                .catch((e) => {
                    if (e.response) {
                        console.log("e.response: => ", e.response);
                    } else if (e.request) {
                        console.log("e.request: =>", e.request);
                    } else {
                        console.log(e, "err =>");
                    }

                    if (e?.response?.status === 401) {
                        const columnSetting = localStorage.getItem("columnSetting");
                        const columnLeadSetting = localStorage.getItem("columnLeadSetting");

                        localStorage.clear();
                        if (columnSetting) {
                            localStorage.setItem('columnSetting', columnSetting)
                        }
                        if (columnLeadSetting) {
                            localStorage.setItem('columnLeadSetting', columnLeadSetting)
                        }
                        window.location.href = "/dang-nhap"
                    }
                    reject(e);
                });
        });
    },
    // requestFetch(methodType, url, headers, body) {
    //     return new Promise((resolve, reject) => {
    //         console.log(body);
    //         let fetchParam = {
    //             method: methodType,
    //             headers,
    //             mode: 'no-cors'
    //         }

    //         if (methodType.toLowerCase() !== "get") {
    //             fetchParam.body = body;

    //         }
    //         return fetch(url.getServiceUrl(), fetchParam).then((json) => {
    //             if (!json.ok) {
    //                 reject(json);
    //             }
    //             else
    //                 resolve(json);
    //         }).catch((e) => {
    //             console.log(e.message);
    //             reject(e);
    //         });
    //     })
    // },
};

// format tiền
// Người tạo :DXQuang(5/8/2019)
Number.prototype.formatMoney = function () {
    return this.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};
