import styled from "styled-components";

const Heading1 = styled("h1")`
  font-weight: normal;
  font-size: 1.375rem;
  line-height: 1.625rem;
  color: #2d3540;
  background-color: #fff;
  padding: 0;
  margin: 0;
`;

export { Heading1 };
