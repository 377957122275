import React from 'react';
import {
    Form,
    InputNumber,
    Input
} from "antd";
import "./styles.scss"

function FormInput({
    name,
    label = "",
    placeholder,
    required = false,
    message = `Please input your ${name}!`,
    rules = [],
    isMultiLine,
    isNumber,
    disabled = false,
    defaultValue,
    onChange,
    maxLength,
    max,
    min,
    showCount = false,
    formatter,
    parser,
    addonAfter,
    autoSize,
}) {

    return (
        <Form.Item
            label={label}
            rules={[
                {
                    required,
                    message,
                },
                ...rules,
            ]}
            name={name}
            className="form-input"
        >
            {isNumber ? (
                <InputNumber
                    disabled={disabled}
                    placeholder={placeholder}
                    min={min == 0 ? 0 : min ? min : 1}
                    max={max ? max : null}
                    onChange={(value) => onChange && onChange(name, value)}
                    formatter={formatter ? formatter : value => value}
                    parser={parser ? parser : value => value}
                    addonAfter={addonAfter}
                />
            ) : isMultiLine ? (
                <Input.TextArea
                    placeholder={placeholder}
                    rows={4}
                    onChange={(e) => onChange && onChange(name, e.target.value)}
                    maxLength={maxLength ? maxLength : 99999}
                    disabled={disabled}
                    showCount={showCount}
                    autoSize={autoSize}
                />
            ) : (
                <Input
                    disabled={disabled}
                    placeholder={placeholder}
                    maxLength={maxLength ? maxLength : 150}
                    onChange={(e) => onChange && onChange(name, e.target.value)}
                    showCount={showCount}
                />
            )}
        </Form.Item>
    );
}

export default FormInput;
