import React, { Component } from "react";
import { connect } from "react-redux";
import userProvider from "./data-access/user-provider";

import constants from "./resources/strings";
import { BrowserRouter } from "react-router-dom";
import Main from "./Main";
import { CookiesProvider } from 'react-cookie';
// import Test from './containers/Test/Test';
const AppMain = () => {
  return (
    <BrowserRouter>
      <Main />
      {/* <Test /> */}
    </BrowserRouter>
  );
};

class App2 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.props.dispatch({
      type: constants.action.action_user_login,
      value: userProvider.getAccountStorage(),
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps?.userApp?.currentUser)
      userProvider
        .validateToken(nextProps.userApp.loginToken)
        .then((result) => {
          if (result.data.code === 401) {
            const columnSetting = localStorage.getItem("columnSetting");
            const columnLeadSetting = localStorage.getItem("columnLeadSetting");
            localStorage.clear();
            if(columnSetting) {
              localStorage.setItem('columnSetting', columnSetting)
            }
            if(columnLeadSetting) {
              localStorage.setItem('columnLeadSetting', columnLeadSetting)
            }
            window.location.href = "/dang-nhap";
          }
        })
        .catch((err) => { });
  }

  render() {
    return (
      <CookiesProvider>
        <AppMain />
      </CookiesProvider>
    )
  }
}
function mapStateToProps(state) {
  return {
    userApp: state.userApp,
  };
}

export default connect(mapStateToProps)(App2);
