import { useEffect, useState, useRef, useCallback } from 'react';
export const useInfinityList = ({fetchData, otherCondition}) => {
	const [loading, setLoading] = useState(true);
	const [pageNum, setPageNum] = useState(0);
	const [lastElement, setLastElement] = useState(null);
	const lastCurrentElement = useRef();
	const [forceRefresh, setForceRefresh] = useState(new Date())
	
	const observer = useRef(
		new IntersectionObserver((entries) => {
			const first = entries[0];
			if (first.isIntersecting) {
				setPageNum((no) => no + 1);
			}
		})
	);

	useEffect(() => {
		if (lastCurrentElement.current !== lastElement && otherCondition) {
			fetchData(pageNum);
			lastCurrentElement.current = lastElement;
		}
	}, [pageNum, fetchData, otherCondition, forceRefresh]);

	useEffect(() => {
		const currentElement = lastElement;
		const currentObserver = observer.current;

		if (currentElement) {
			currentObserver.observe(currentElement);
		}

		return () => {
			if (currentElement) {
				currentObserver.unobserve(currentElement);
			}
		};
	}, [lastElement]);
	const onRefresh = useCallback(() => {
		setPageNum(0);
		setLastElement(null);
		setForceRefresh(new Date());
		lastCurrentElement.current = undefined;
	},[])

	return {setLastElement, loading, setLoading, onRefresh};
};

