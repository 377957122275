import adminProvider from "@data-access/admin-provider";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Login from "../user/containners/account/Login";
import "./App.scss";
import { SideBar } from './components';
// routes config
import routes from "./configs/routes";
import { Menus } from './menus';
import WithRoot from "./WithRoot";
import ModalNotification from "./containers-v2/ModalNotification/ModalNotification";
export const modalNotificationRef = React.createRef()
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: [],
    };
  }

  componentDidMount = () => {
    window.registerEvent();
    this.getAuthorities();
  }

  getAuthorities = async () => {
    const { authorities } = await adminProvider.getAuthorities();
    this.setState({ authorities }, () => {
      this.getMenu();
    });
  };

  getMenu() {
    const authorities = this.state?.authorities || [];

    const isAdmin = authorities.includes("ADMIN");

    if (isAdmin) {
      this.setState({ menus: [...Menus] });
    } else {
      let newMenus = Menus.filter((item, index) => {
        if (!item.authorities || item.authorities?.findIndex(auth => authorities.indexOf(auth) > -1) > -1) return true;
        return false;
      });
      newMenus = newMenus.map(item => {
        if (item.menus && item.menus.length > 0) {
          let subMenu = item.menus.filter(sub => {
            if (!sub.authorities || sub.authorities?.findIndex(auth => authorities.indexOf(auth) > -1) > -1) return true;
            return false;
          })
          return { ...item, menus: subMenu }
        }
        return item;
      })
      this.setState({ menus: [...newMenus] });
    }
  }
  openMenu(item) {
    item.open = !item.open;
    this.setState({ menus: [...this.state.menus] });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className="page-wrapper">
          <div className="page-inner">
            <SideBar menus={this.state.menus} />
            <div className="page-content-wrapper">
              {/* <Header /> */}

              <main id="js-page-content" role="main" className="page-content">
                {/* <Breadcrumbs /> */}

                <div className="page-content-inner">
                  <Switch>
                    {routes.map((route, idx) => {
                      return route.component ? (
                        <Route
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          render={(props) => <route.component {...props} />}
                        />
                      ) : null;
                    })}
                  </Switch>
                  {!this.props.userApp.isLogin && (
                    <Redirect to="/dang-nhap" component={Login} />
                  )}
                  <ModalNotification ref={modalNotificationRef} />
                </div>
              </main>
            </div>
          </div>
        </div>
        {/* <SettingLayout /> */}
      </div>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  sidebar: {
    textAlign: "left",
  },
});

function mapStateToProps(state) {
  return {
    userApp: state.userApp,
  };
}
export default connect(mapStateToProps)(WithRoot(withStyles(styles)(Home)));
