import React from 'react';
import Authorization from '../../../securities/Authorization';
import Loadable from 'react-loadable';
import Loading from 'src/components/Loading';
import PrescriptionDetail from '../containers-v2/PrescriptionManagement/Detail';

// function Loading(error) {
//   console.log("error: ", error);
//   return <div>Loading...</div>;
// }

// const Dashboard = Loadable({
//   loader: () => import("../../../pages/dashboard/general"),
//   loading: Loading,
const User = Loadable({
    loader: () => import('../containers-v2/UserManagement'),
    loading: Loading,
});

const HotDeal = Loadable({
    loader: () => import('../containers-v2/HotDeal'),
    loading: Loading,
});

const AlbumHotDeal = Loadable({
    loader: () => import('../containers-v2/AlbumHotDeal'),
    loading: Loading,
});

const DeliveryManagement = Loadable({
    loader: () => import('../containers-v2/Delivery'),
    loading: Loading,
});

const DeliveryDetail = Loadable({
    loader: () => import('../containers-v2/Delivery/DeliveryDetail'),
    loading: Loading,
});

const SymptomManagement = Loadable({
    loader: () => import('../containers-v2/SymptomManagement'),
    loading: Loading,
});

const Doctor = Loadable({
    loader: () => import('../containers-v2/doctor'),
    loading: Loading,
});
const Company = Loadable({
    loader: () => import('../containers-v2/Company'),
    loading: Loading,
});
const PersonCompany = Loadable({
    loader: () => import('../containers-v2/PersonCompany'),
    loading: Loading,
});
const LeadManager = Loadable({
    loader: () => import('../containers-v2/LeadManager'),
    loading: Loading,
});
const CreateLead = Loadable({
    loader: () => import('../containers-v2/LeadManager/CreateLead'),
    loading: Loading,
});
const MgrAdmin = Loadable({
    loader: () => import('../containers/mgr-admin'),
    loading: Loading,
});
const Question = Loadable({
    loader: () => import('../containers/question'),
    loading: Loading,
});
const QuestionV2 = Loadable({
    loader: () => import('../containers-v2/QuestionManagement'),
    loading: Loading,
});
const QuestionDetail = Loadable({
    loader: () => import('../containers-v2/QuestionManagement/Detail'),
    loading: Loading,
});
const MgrRole = Loadable({
    loader: () => import('../containers/role'),
    loading: Loading,
});
const MgrSpecialist = Loadable({
    loader: () => import('../containers-v2/specialty'),
    loading: Loading,
});
const MgrServiceType = Loadable({
    loader: () => import('../containers-v2/serviceType'),
    loading: Loading,
});
// const ServiceHighLight = Loadable({
//     loader: () =>
//         import("../containers/service-highlight"),
//     loading: Loading,
// });
const ListsService = Loadable({
    loader: () => import('../containers-v2/ListsService'),
    loading: Loading,
});
const Category = Loadable({
    loader: () => import('../containers-v2/categoryService'),
    loading: Loading,
});
const MgrBooking = Loadable({
    loader: () => import('../containers/booking'),
    loading: Loading,
});
const MgrBookingV2 = Loadable({
    loader: () => import('../containers-v2/BookingManagement'),
    loading: Loading,
});
const NewBooking = Loadable({
    loader: () => import('../containers-v2/NewBooking'),
    loading: Loading,
});
const MgrBookingDetail = Loadable({
    loader: () => import('../containers-v2/BookingManagement/Detail'),
    loading: Loading,
});
const MgrTicket = Loadable({
    loader: () => import('../containers/ticket'),
    loading: Loading,
});
const TicketInfo = Loadable({
    loader: () => import('../containers/ticket/ticket-controller'),
    loading: Loading,
});
const MedicineCategory = Loadable({
    loader: () => import('../containers/medicine-category'),
    loading: Loading,
});
const MedicineStore = Loadable({
    loader: () => import('../containers/medicine-store' /* webpackChunkName: "chunk-medicine-store" */),
    loading: Loading,
});
const MedicineController = Loadable({
    loader: () => import('../containers/medicine-controler' /* webpackChunkName: "chunk-medicine-store" */),
    loading: Loading,
});
const UserTracking = Loadable({
    loader: () => import('../containers/user-tracking'),
    loading: Loading,
});
const Advertise = Loadable({
    loader: () => import('../containers/advertise'),
    loading: Loading,
});
const ImportExcel = Loadable({
    loader: () => import('../containers/user-tracking/importexcel'),
    loading: Loading,
});
const Voucher = Loadable({
    loader: () => import('../containers/voucher'),
    loading: Loading,
});
const Logs = Loadable({
    loader: () => import('../containers-v2/logs'),
    loading: Loading,
});
const LogsSearch = Loadable({
    loader: () => import('../containers-v2/LogsSearch'),
    loading: Loading,
});
const Wallets = Loadable({
    loader: () => import('../containers/transaction'),
    loading: Loading,
});
const Menu = Loadable({
    loader: () => import('../containers-v2/Menu/index'),
    loading: Loading,
});
const SubMenu1 = Loadable({
    loader: () => import('../containers/menu/subMenu1'),
    loading: Loading,
});
const SubMenu2 = Loadable({
    loader: () => import('../containers/menu/subMenu2'),
    loading: Loading,
});
const SlideItem = Loadable({
    loader: () => import('../containers/slides/slide-item'),
    loading: Loading,
});
const SlideItemV2 = Loadable({
    loader: () => import('../containers-v2/SlideItem'),
    loading: Loading,
});
// const Slide = Loadable({
//     loader: () =>
//         import ("../containers/slides/slide"),
//     loading: Loading,
// });
const SlideV2 = Loadable({
    loader: () => import('../containers-v2/SlideManagement'),
    loading: Loading,
});
const SlidePlace = Loadable({
    loader: () => import('../containers-v2/SlidePlace'),
    loading: Loading,
});
// const SlidePlace = Loadable({
//     loader: () =>
//         import("../containers/slides/slide-place"),
//     loading: Loading,
// });
// const MgrNews = Loadable({
//   loader: () => import("../containers/news/MgrNews"),
//   loading: Loading,
// });
// const MgrCategory = Loadable({
//   loader: () => import("../containers/news/MrgCategory"),
//   loading: Loading,
// });
// const CreateNews = Loadable({
//   loader: () => import("../containers/news/CreateNews"),
//   loading: Loading,
// });
const MgrNews = Loadable({
    loader: () => import('../containers-v2/MgrNews/index'),
    loading: Loading,
});
const NewsCategory = Loadable({
    loader: () => import('../containers-v2/NewsCategory'),
    loading: Loading,
});
const CreateNews = Loadable({
    loader: () => import('../containers-v2/MgrNews/New/index'),
    loading: Loading,
});
const CreateCategory = Loadable({
    loader: () => import('../containers/news-category/CreateCategory'),
    loading: Loading,
});
const Notification = Loadable({
    loader: () => import('../containers/notification'),
    loading: Loading,
});
const NotificationV2 = Loadable({
    loader: () => import('../containers-v2/Notification'),
    loading: Loading,
});
const FilterNoti = Loadable({
    loader: () => import('../containers/notification/filter'),
    loading: Loading,
});
const FilterNotiV2 = Loadable({
    loader: () => import('../containers-v2/NotificationGroup'),
    loading: Loading,
});
const Calls = Loadable({
    loader: () => import('../containers/calls'),
    loading: Loading,
});
const CallsV2 = Loadable({
    loader: () => import('../containers-v2/CallManagement'),
    loading: Loading,
});
const Payment = Loadable({
    loader: () => import('../containers/payment'),
    loading: Loading,
});
const PaymentV2 = Loadable({
    loader: () => import('../containers-v2/PaymentManagement'),
    loading: Loading,
});
const ListGroups = Loadable({
    loader: () => import('../containers-v2/ListGroups'),
    loading: Loading,
});
const AddGroups = Loadable({
    loader: () => import('../containers-v2/ListGroups/AddGroup'),
    loading: Loading,
});
const ArticleDetail = Loadable({
    loader: () => import('../containers-v2/ListGroups/AddGroup/Article/ArticleDetail'),
    loading: Loading,
});
const ICD = Loadable({
    loader: () => import('../containers-v2/ICD'),
    loading: Loading,
});
const AI = Loadable({
    loader: () => import('../containers-v2/AI'),
    loading: Loading,
});

const Specimen = Loadable({
    loader: () => import('../containers-v2/specimen'),
    loading: Loading,
});
const SpecimenGroups = Loadable({
    loader: () => import('../containers-v2/specimenGroup'),
    loading: Loading,
});
const COVIDquestions = Loadable({
    loader: () => import('../containers/COVID'),
    loading: Loading,
});
const COVIDquestionnaires = Loadable({
    loader: () => import('../containers/COVID/questionnaires'),
    loading: Loading,
});
// const Features = Loadable({
//     loader: () =>
//         import("@containers/features"),
//     loading: Loading,
// });
const ServiceHighLight = Loadable({
    loader: () => import('../containers-v2/ServiceHighlight'),
    loading: Loading,
});
// const DashBoardBooking = Loadable({
//     loader: () =>
//         import("@containers/dashboard/booking"),
//     loading: Loading,
// });
const DashBoardBooking = Loadable({
    loader: () => import('../containers-v2/DashboardBooking'),
    loading: Loading,
});
const DashBoardUserTracking = Loadable({
    loader: () => import('../containers-v2/DashboardUserTracking'),
    loading: Loading,
});
const DashBoardQuestions = Loadable({
    loader: () => import('../containers-v2/DashboardQuestions'),
    loading: Loading,
});
const Hospitals = Loadable({
    loader: () => import('../containers-v2/hospital'),
    loading: Loading,
});
const HospitalDetail = Loadable({
    loader: () => import('../containers-v2/hospital/detailHospital'),
    loading: Loading,
});
const CrudHospital = Loadable({
    loader: () => import('../containers-v2/hospital/detailHospital/CrudHospital'),
    loading: Loading,
});

const Admin = Loadable({
    loader: () => import('../containers/admin-controller/Admin'),
    loading: Loading,
});

const AdminV2 = Loadable({
    loader: () => import('../containers-v2/AdminAccount'),
    loading: Loading,
});

const Roles = Loadable({
    loader: () => import('../containers/admin-controller/Role'),
    loading: Loading,
});

const RolesV2 = Loadable({
    loader: () => import('../containers-v2/ListRole'),
    loading: Loading,
});

const Guide = Loadable({
    loader: () => import('../containers-v2/support/guide'),
    loading: Loading,
});
const CreateGuide = Loadable({
    loader: () => import('../containers-v2/support/guide/create'),
    loading: Loading,
});
const UpdateGuide = Loadable({
    loader: () => import('../containers-v2/support/guide/update'),
    loading: Loading,
});
const DetailGuide = Loadable({
    loader: () => import('../containers-v2/support/guide/detail'),
    loading: Loading,
});
const QandA = Loadable({
    loader: () => import('../containers-v2/support/QandA'),
    loading: Loading,
});
const CreateQandA = Loadable({
    loader: () => import('../containers-v2/support/QandA/create'),
    loading: Loading,
});
const UpdateQandA = Loadable({
    loader: () => import('../containers-v2/support/QandA/update'),
    loading: Loading,
});
const DetailQandA = Loadable({
    loader: () => import('../containers-v2/support/QandA/detail'),
    loading: Loading,
});
const Video = Loadable({
    loader: () => import('../containers-v2/support/Video'),
    loading: Loading,
});

const Pharmacy = Loadable({
    loader: () => import('../containers-v2/Pharmacy'),
    loading: Loading,
});

const ProfilePharmacy = Loadable({
    loader: () => import('../containers-v2/Pharmacy/Detail'),
    loading: Loading,
});

const MedicineGroupV2 = Loadable({
    loader: () => import('../containers-v2/MedicineCategory'),
    loading: Loading,
});

const MedicinesV2 = Loadable({
    loader: () => import('../containers-v2/MedicineManagement'),
    loading: Loading,
});

const OrdersV2 = Loadable({
    loader: () => import('../containers-v2/OrderManagement'),
    loading: Loading,
});

const OrderDetailV2 = Loadable({
    loader: () => import('../containers-v2/OrderManagement/Detail'),
    loading: Loading,
});

const PrescriptionManagement = Loadable({
    loader: () => import('../containers-v2/PrescriptionManagement'),
    loading: Loading,
});

const CTKM = Loadable({
    loader: () => import('../containers-v2/CTKM'),
    loading: Loading,
});

const CTKMDetail = Loadable({
    loader: () => import('../containers-v2/CTKM/Detail'),
    loading: Loading,
});
const Revenue = Loadable({
    loader: () => import('../containers/revenue'),
    loading: Loading,
});
const RevenueV2 = Loadable({
    loader: () => import('../containers-v2/RevenueReport'),
    loading: Loading,
});
const Report = Loadable({
    loader: () => import('../containers-v2/Report'),
    loading: Loading,
});
const ReportOverTime = Loadable({
    loader: () => import('../containers-v2/Report/ReportOverTime'),
    loading: Loading,
});
const ReportLead = Loadable({
    loader: () => import('../containers-v2/Report/ReportLead'),
    loading: Loading,
});
const ReportUtilities = Loadable({
    loader: () => import('../containers-v2/Report/Utilities'),
    loading: Loading,
});
const CustomerConvenience = Loadable({
    loader: () => import('../containers-v2/Report/Utilities/CustomerConvenience'),
    loading: Loading,
});
const UtilityTransation = Loadable({
    loader: () => import('../containers-v2/Report/Utilities/UtilityTransactions'),
    loading: Loading,
});
const ServicePack = Loadable({
    loader: () => import('../containers-v2/Report/Utilities/ServicePack'),
    loading: Loading,
});
const ReportOrderOverTime = Loadable({
    loader: () => import('../containers-v2/Report/Order/ReportOrdersOverTime'),
    loading: Loading,
});
const ReportOrderMerchant = Loadable({
    loader: () => import('../containers-v2/Report/Order/ReportOrdersMerchant'),
    loading: Loading,
});
const ReportOrderProdution = Loadable({
    loader: () => import('../containers-v2/Report/Order/ReportOrdersProduction'),
    loading: Loading,
});
const ReportOrderPerson = Loadable({
    loader: () => import('../containers-v2/Report/Order/ReportOrdersPerson'),
    loading: Loading,
});
const ReportOrder = Loadable({
    loader: () => import('../containers-v2/Report/Order'),
    loading: Loading,
});
const ReportsDoctorQuality = Loadable({
    loader: () => import('../containers-v2/Report/ReportsDoctorQuality'),
    loading: Loading,
});
const ReporUserTracking = Loadable({
    loader: () => import('../containers-v2/Report/UserTracking'),
    loading: Loading,
});
const UserTrackingAge = Loadable({
    loader: () => import('../containers-v2/Report/UserTracking/AgeTrackings/index'),
    loading: Loading,
});
const UserTrackingAddress = Loadable({
    loader: () => import('../containers-v2/Report/UserTracking/TrackingAddress/index'),
    loading: Loading,
});
const UserTrackingGender = Loadable({
    loader: () => import('../containers-v2/Report/UserTracking/GenderTracking/index'),
    loading: Loading,
});
const RevenueMedicalService = Loadable({
    loader: () => import('../containers-v2/Report/RevenueMedicalService'),
    loading: Loading,
});
const RevenueMedicalHospital = Loadable({
    loader: () => import('../containers-v2/Report/RevenueMedicalHospital'),
    loading: Loading,
});
const RevenueMedicalPerson = Loadable({
    loader: () => import('../containers-v2/Report/RevenueMedicalPerson'),
    loading: Loading,
});
const DetailedReport = Loadable({
    loader: () => import('../containers-v2/Report/DetailedReport'),
    loading: Loading,
});
const DetailORReport = Loadable({
    loader: () => import('../containers-v2/Report/Order/ReportOrdersDetail'),
    loading: Loading,
});
const ConfigHome = Loadable({
    loader: () => import('../containers-v2/ConfigHome'),
    loading: Loading,
});
const Gift = Loadable({
    loader: () => import('../containers-v2/CTKM/Gift'),
    loading: Loading,
});
const Game = Loadable({
    loader: () => import('../containers-v2/game'),
    loading: Loading,
});
const CreateGame = Loadable({
    loader: () => import('../containers-v2/game/create'),
    loading: Loading,
});
const DetailGame = Loadable({
    loader: () => import('../containers-v2/game/detail'),
    loading: Loading,
});
const LevelCardManagement = Loadable({
    loader: () => import('../containers-v2/LevelCardManagement/index'),
    loading: Loading,
});
const LevelCardCreate = Loadable({
    loader: () => import('../containers-v2/LevelCardManagement/New/index'),
    loading: Loading,
});
const MemberCard = Loadable({
    loader: () => import('../containers-v2/MemberCard'),
    loading: Loading,
});
const MemberCardDetail = Loadable({
    loader: () => import('../containers-v2/MemberCard/Detail'),
    loading: Loading,
});
const EarnPointConfig = Loadable({
    loader: () => import('../containers-v2/EarnPointConfig'),
    loading: Loading,
});

const RedeemPointsManagement = Loadable({
    loader: () => import('../containers-v2/RedeemPointsManagement'),
    loading: Loading,
});
const ConvenientsManagement = Loadable({
    loader: () => import('../containers-v2/ConvenientsManagement'),
    loading: Loading,
});

const ConvenientTransactionsManagement = Loadable({
    loader: () => import('../containers-v2/ConvenientTransactionsManagement'),
    loading: Loading,
});

const ConvenientTransactionsDetail = Loadable({
    loader: () => import('../containers-v2/ConvenientTransactionsManagement/Detail'),
    loading: Loading,
});

const UrgentCall = Loadable({
    loader: () => import('../containers-v2/UrgentCall'),
    loading: Loading,
});
const UrgentCallDetail = Loadable({
    loader: () => import('../containers-v2/UrgentCall/Detail'),
    loading: Loading,
});

const ConvenientTransactionChat = Loadable({
    loader: () => import('../containers-v2/ConvenientTransactionChat'),
    loading: Loading,
});

const ConvenientTransactionChatDetail = Loadable({
    loader: () => import('../containers-v2/ConvenientTransactionChat/Detail'),
    loading: Loading,
});

const ConvenientSessionChat = Loadable({
    loader: () => import('../containers-v2/ConvenientSessionChat'),
    loading: Loading,
});
// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const RemindMedicine = Loadable({
    loader: () => import('../containers-v2/RemindMedicine'),
    loading: Loading,
});
const RemindMedicineDetail = Loadable({
    loader: () => import('../containers-v2/RemindMedicine/Detail'),
    loading: Loading,
});

const ChatHistory = Loadable({
    loader: () => import('../containers-v2/ChatHistory'),
    loading: Loading,
});
const RedirectConfig = Loadable({
    loader: () => import('../containers-v2/Setting301'),
    loading: Loading,
});
const ListSurvey = Loadable({
    loader: () => import('../containers-v2/ActualSurvey'),
    loading: Loading,
});
const CreateSurvey = Loadable({
    loader: () => import('../containers-v2/ActualSurvey/CreateSurvey/index'),
    loading: Loading,
});
const MessageManager = Loadable({
    loader: () => import('../containers-v2/MessageManager'),
    loading: Loading,
});
// Báo cáo rating servey
const ReportServey = Loadable({
    loader: () => import('../containers-v2/Report/Servey'),
    loading: Loading,
});
const ReportServeyDoctor = Loadable({
    loader: () => import('../containers-v2/Report/Servey/ReportServeyDoctor'),
    loading: Loading,
});
const ReportServeyHopital = Loadable({
    loader: () => import('../containers-v2/Report/Servey/ReportServeyHopital'),
    loading: Loading,
});
const ReportServeyDetail = Loadable({
    loader: () => import('../containers-v2/Report/Servey/ReportServeyDetail'),
    loading: Loading,
});
const routes = [
    {
        path: '/admin/marketing/promotion',
        name: '',
        exact: true,
        component: CTKM,
    },
    {
        path: '/admin/marketing/promotion/:id',
        name: '',
        exact: true,
        component: CTKMDetail,
    },
    {
        path: '/admin/marketing/gift',
        name: '',
        exact: true,
        component: Gift,
    },
    {
        path: '/admin/marketing/create-promotion',
        name: '',
        exact: true,
        component: CTKMDetail,
    },
    {
        path: '/admin/marketing/create-voucher',
        name: '',
        exact: true,
        component: CTKMDetail,
    },
    {
        path: '/admin/marketing/create-shipping',
        name: '',
        exact: true,
        component: CTKMDetail,
    },
    { path: '/admin/pharmacies', name: '', component: Pharmacy, exact: true },
    { path: '/admin/pharmacies/:id', name: '', component: ProfilePharmacy, exact: true },
    { path: '/admin/pharmacies/edit/:id', name: '', component: ProfilePharmacy, exact: true },
    {
        path: '/admin/pharmacy/medicine-group',
        name: '',
        component: MedicineGroupV2,
    },
    { path: '/admin/pharmacy/medicines', name: '', component: MedicinesV2 },
    { path: '/admin/pharmacy/orders', exact: true, name: '', component: OrdersV2 },
    {
        path: '/admin/pharmacy/orders/:id',
        name: '',
        exact: true,
        component: OrderDetailV2,
    },
    {
        path: '/admin/pharmacy/prescriptions',
        name: '',
        exact: true,
        component: PrescriptionManagement,
    },
    {
        path: '/admin/pharmacy/prescriptions/:id',
        name: '',
        exact: true,
        component: PrescriptionDetail,
    },

    // { path: '/admin/dashboard', name: "Dashboard", component: Dashboard },
    { path: '/admin/admin-list', name: '', component: AdminV2 },
    { path: '/admin/admin-roles', name: '', component: RolesV2 },

    { path: '/admin/user', name: '', component: User },
    { path: '/admin/doctor', name: '', component: Doctor },
    { path: '/admin/company', name: '', component: Company },
    { path: '/admin/person-company', name: '', component: PersonCompany },
    { path: '/admin/mgr-admin', name: '', component: MgrAdmin },
    { path: '/admin/question/:id', name: '', component: QuestionDetail },
    { path: '/admin/question', name: '', component: QuestionV2 },
    { path: '/admin/role', name: '', component: MgrRole },
    { path: '/admin/specialist', name: '', component: MgrSpecialist },
    { path: '/admin/service-type', name: '', component: MgrServiceType },
    { path: '/admin/service-highlight', name: '', component: ServiceHighLight },
    { path: '/admin/list-service', name: '', component: ListsService },
    { path: '/admin/category', name: '', component: Category },
    { path: '/admin/booking/:id', name: '', component: MgrBookingDetail },
    { path: '/admin/booking', name: '', component: MgrBookingV2 },
    { path: '/admin/new-booking', name: '', component: NewBooking },
    { path: '/admin/medicine-category', name: '', component: MedicineCategory },
    { path: '/admin/medicine-store', name: '', component: MedicineStore },
    { path: '/admin/medicine-controll', name: '', component: MedicineController },
    // { path: "/admin/user-tracking", name: "", component: UserTracking },
    { path: '/admin/import-user', name: '', component: ImportExcel },
    { path: '/admin/voucher', name: '', component: Voucher },
    { path: '/admin/logs', name: '', component: Logs },
    { path: '/admin/logs-search', name: '', component: LogsSearch },
    { path: '/admin/advertise', name: '', component: Advertise },
    // { path: '/admin/user-info', name: "", component: Wallets },
    { path: '/admin/transaction', name: '', component: Wallets },
    { path: '/admin/menu', name: '', component: Menu },
    {
        path: '/admin/sub-menu/:id',
        exact: true,
        name: 'Quản lý menu cấp 2',
        component: SubMenu1,
    },
    {
        path: '/admin/sub-menu2/:id',
        exact: true,
        name: 'Quản lý menu cấp 3',
        component: SubMenu2,
    },
    { path: '/admin/slide-item', name: '', component: SlideItemV2 },
    { path: '/admin/slide', name: '', component: SlideV2 },
    { path: '/admin/slide-place', name: '', component: SlidePlace },
    { path: '/admin/news/create-news/:alias', name: '', component: CreateNews },
    { path: '/admin/news/create-news', name: '', component: CreateNews },
    { path: '/admin/create-category-news', name: '', component: CreateCategory },
    { path: '/admin/news', name: '', component: MgrNews },
    { path: '/admin/news-category', name: '', component: NewsCategory },
    { path: '/admin/notification', name: '', component: NotificationV2 },
    { path: '/admin/notification-filter', name: '', component: FilterNotiV2 },
    { path: '/admin/calls', name: '', component: CallsV2 },
    { path: '/admin/payment', name: '', component: PaymentV2 },
    { path: '/admin/ICD', name: '', component: ICD },
    { path: '/admin/chan-doan-AI', name: '', component: AI },
    { path: '/admin/tu-dien-xet-nghiem', name: '', component: Specimen },
    { path: '/admin/nhom-xet-nghiem', name: '', component: SpecimenGroups },
    { path: '/admin/redirect-configs', name: '', component: RedirectConfig },

    { path: '/admin/COVID/questions', name: '', component: COVIDquestions },
    { path: '/admin/COVID/questionnaires', name: '', component: COVIDquestions },
    // { path: "/admin/features", name: "", component: Features },
    { path: '/admin/features', name: '', component: ServiceHighLight },
    { path: '/admin/dashboard/booking', name: '', component: DashBoardBooking },
    {
        path: '/admin/dashboard/user-tracking',
        name: '',
        component: DashBoardUserTracking,
    },
    {
        path: '/admin/dashboard/questions',
        name: '',
        component: DashBoardQuestions,
    },
    { path: '/admin/hospitals', name: '', component: Hospitals, exact: true },
    { path: '/admin/hospitals/create', name: '', component: CrudHospital, exact: true },
    { path: '/admin/hospitals/update/:hospitalId', name: '', component: CrudHospital, exact: true },
    {
        path: '/admin/hospital-profile/:hospitalId',
        name: '',
        component: HospitalDetail,
        exact: true,
    },
    { path: '/admin/guide', name: '', component: Guide, exact: true  },
    { path: '/admin/QandA', name: '', component: QandA, exact: true  },
    { path: '/admin/video', name: '', component: Video },
    { path: '/admin/guide/create', name: '', component: CreateGuide, exact: true  },
    { path: '/admin/guide/update/:guideId', name: '', component: UpdateGuide, exact: true },
    { path: '/admin/guide/detail/:guideId', name: '', component: DetailGuide, exact: true },
    { path: '/admin/QandA/create', name: '', component: CreateQandA, exact: true },
    { path: '/admin/QandA/update/:QandAId', name: '', component: UpdateQandA, exact: true },
    { path: '/admin/QandA/detail/:QandAId', name: '', component: DetailQandA, exact: true },
    { path: '/admin/report/revenue', name: '', component: RevenueV2, exact: true },
    { path: '/admin/reportOrder', name: '', component: ReportOrder, exact: true },
    { path: '/admin/report-doctor-quality', name: '', component: ReportsDoctorQuality },
    { path: '/admin/user-tracking', name: '', component: ReporUserTracking },
    { path: '/admin/report-over-time', name: '', component: ReportOverTime },
    { path: '/admin/lead-report', name: '', component: ReportLead },
    { path: '/admin/utilities', name: '', component: ReportUtilities, exact: true },
    { path: '/admin/report/revenue-medical-service', name: '', component: RevenueMedicalService, exact: true },
    { path: '/admin/report/revenue-medical-hospital', name: '', component: RevenueMedicalHospital, exact: true },
    { path: '/admin/report/revenue-medical-person', name: '', component: RevenueMedicalPerson, exact: true },
    { path: '/admin/report/detail-report', name: '', component: DetailedReport, exact: true },
    { path: '/admin/config-home', name: '', component: ConfigHome },
    { path: '/admin/report-order-time', name: '', component: ReportOrderOverTime },
    { path: '/admin/report-order-production', name: '', component: ReportOrderProdution },
    { path: '/admin/report-order-person', name: '', component: ReportOrderPerson },
    { path: '/admin/detail-order-report', name: '', component: DetailORReport },
    { path: '/admin/report-customer-convenience', name: '', component: CustomerConvenience },
    { path: '/admin/report-service-pack', name: '', component: ServicePack },
    { path: '/admin/tracking-gender', name: '', component: UserTrackingGender },
    { path: '/admin/tracking-age', name: '', component: UserTrackingAge },
    { path: '/admin/tracking-address', name: '', component: UserTrackingAddress },
    { path: '/admin/reportOrder/report-order-time', name: '', component: ReportOrderOverTime, exact: true },
    { path: '/admin/reportOrder/report-order-merchant', name: '', component: ReportOrderMerchant, exact: true },
    { path: '/admin/reportOrder/report-order-production', name: '', component: ReportOrderProdution, exact: true },
    { path: '/admin/reportOrder/report-order-person', name: '', component: ReportOrderPerson, exact: true },
    { path: '/admin/reportOrder/detail-order-report', name: '', component: DetailORReport, exact: true },
    { path: '/admin/utilities/report-customer-convenience', name: '', component: CustomerConvenience, exact: true },
    { path: '/admin/utilities/report-service-pack', name: '', component: ServicePack, exact: true },
    { path: '/admin/utilities/report-utility-transation', name: '', component: UtilityTransation, exact: true },
    { path: '/admin/report', name: '', component: Report, exact: true },
    { path: '/admin/report/report-over-time', name: '', component: ReportOverTime, exact: true },
    { path: '/admin/report-servey', name: '', component: ReportServey, exact: true },
    { path: '/admin/report-servey/detail', name: '', component: ReportServeyDetail, exact: true },
    { path: '/admin/report-servey/hopital', name: '', component: ReportServeyHopital, exact: true },
    { path: '/admin/report-servey/doctor', name: '', component: ReportServeyDoctor, exact: true },
    {
        path: '/admin/game',
        name: '',
        exact: true,
        component: Game,
    },
    {
        path: '/admin/game/create',
        name: '',
        exact: true,
        component: CreateGame,
    },
    {
        path: '/admin/game/:gameId',
        name: '',
        exact: true,
        component: DetailGame,
    },
    {
        path: '/admin/accumulatePoints/levelCard',
        name: '',
        exact: true,
        component: LevelCardManagement,
    },
    {
        path: '/admin/accumulatePoints/levelCard/create',
        name: '',
        exact: true,
        component: LevelCardCreate,
    },
    {
        path: '/admin/accumulatePoints/levelCard/:id',
        name: '',
        exact: true,
        component: LevelCardCreate,
    },
    {
        path: '/admin/accumulatePoints/levelCard/:id?readOnly',
        name: '',
        exact: true,
        component: LevelCardCreate,
    },
    {
        path: '/admin/membership',
        name: '',
        exact: true,
        component: MemberCard,
    },
    {
        path: '/admin/membership/:id',
        name: '',
        exact: true,
        component: MemberCardDetail,
    },
    {
        path: '/admin/earn-point-config',
        name: '',
        exact: true,
        component: EarnPointConfig,
    },
    {
        path: '/admin/redeem-points',
        name: '',
        exact: true,
        component: RedeemPointsManagement,
    },
    {
        path: '/admin/convenients',
        name: '',
        component: ConvenientsManagement,
    },
    {
        path: '/admin/convenient-transactions',
        name: '',
        component: ConvenientTransactionsManagement,
        exact: true,
    },
    {
        path: '/admin/convenient-transactions/:id',
        name: '',
        exact: true,
        component: ConvenientTransactionsDetail,
    },
    {
        path: '/admin/urgent-calls',
        name: '',
        component: UrgentCall,
    },
    {
        path: '/admin/urgent-call/:id',
        name: '',
        exact: true,
        component: UrgentCallDetail,
    },
    {
        path: '/admin/convenient-transaction-chat/:reference',
        name: '',
        component: ConvenientTransactionChatDetail,
    },
    {
        path: '/admin/convenient-transaction-chat',
        name: '',
        component: ConvenientTransactionChat,
    },
    {
        path: '/admin/convenient-session-chat',
        name: '',
        component: ConvenientSessionChat,
    },
    {
        path: '/admin/remind-medicine/:id',
        name: 'RemindMedicineDetail',
        exact: true,
        component: RemindMedicineDetail,
    },
    {
        path: '/admin/remind-medicine',
        name: 'RemindMedicine',
        component: RemindMedicine,
    },
    {
        path: '/admin/pharmacy/album-hotDeal',
        name: 'AlbumHotDeal',
        exact: true,
        component: AlbumHotDeal,
    },
    {
        path: '/admin/pharmacy/hotdeal/:id',
        name: 'HotDeal',
        exact: true,
        component: HotDeal,
    },
    {
        path: '/admin/pharmacy/chat-history',
        name: 'ChatHistory',
        exact: true,
        component: ChatHistory,
    },
    {
        path: '/admin/pharmacy/delivery',
        name: 'Delivery',
        exact: true,
        component: DeliveryManagement,
    },
    {
        path: '/admin/pharmacy/delivery/:id',
        name: '',
        exact: true,
        component: DeliveryDetail,
    },
    {
        path: '/admin/symptom-management',
        name: 'SymptomManagement',
        exact: true,
        component: SymptomManagement,
    },
    { path: '/admin/lead-manager', name: 'LeadManager', component: LeadManager },
    { path: '/admin/leadManager/create', name: 'CreactLead', component: CreateLead, exact: true },
    { path: '/admin/leadManager/update/:id', name: 'CreactLead', component: CreateLead, exact: true },
    // TODO: DANH SÁCH HỘI NHÓM
    { path: '/admin/list-team', name: 'ListTeam', component: ListGroups, exact: true },
    { path: '/admin/list-team/list-group/:id', name: 'ListGroupDetail', component: AddGroups, exact: true },
    { path: '/admin/list-team/list-group-article/:id', name: 'ListGroupArticleDetail', component: ArticleDetail, exact: true },
    // TODO: Quản lý khảo sát
    { path: '/admin/actual-survey', name: '', component: ListSurvey, exact: true },
    { path: '/admin/actual-survey/create', name: '', component: CreateSurvey, exact: true },
    { path: '/admin/actual-survey/detail/:id', name: '', component: CreateSurvey, exact: true },
    { path: '/admin/actual-survey/edit/:id', name: '', component: CreateSurvey, exact: true },
    //quản lý tin nhắn
    { path: '/admin/quan-ly-tin-nhan', name: '', component: MessageManager, exact: true },
];

export default routes;
