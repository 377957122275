import React, { useRef, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import "./utils/notification"

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import App2 from './App2';
import { Modal, Input } from "antd";
import { Button, Card, CardBody, CardHeader, CardFooter, Col, Row, ModalBody, ModalFooter, ModalHeader, Fade, Label } from 'reactstrap';
import AppReducer from './reducers';
import imageProvider from '@data-access/image-provider';
import { toast } from 'react-toastify';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import IconButton from '@material-ui/core/IconButton';
import { getMessageToken, onReceiveMessage } from './utils/notification';
const store = createStore(AppReducer, applyMiddleware(thunk));


const Kernel = ({ props }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [imageModal, setImageModal] = useState('')
  const [alt, setAlt] = useState('')
  const inputElement = useRef(null);
  const [file, setFile] = useState('')
  useEffect(() => {
    const getFirebaseToken = async () => {
      try {
        const token = await getMessageToken();
        global.accessToken = token;
      } catch (error) {
      }
    }
    getFirebaseToken()
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    }

  }, [])
  const onMessage = (e) => {
    if (e && e.data && e.data.type == "CKEDITOR_EVENT_UPLOAD_IMAGE") {
      setIsVisible(true)
      // if(window.editor)
      //     window.editor.insertHtml('<img src="" />');
    }
  }
  const uploadImage = (event) => {

    let selector = event.target;
    let fileName = selector.value.replace("C:\\fakepath\\", "").toLocaleLowerCase();
    let sizeImage = (event.target.files[0] || {}).size / 1048576;
    if (sizeImage) {
      if (fileName.endsWith(".jpg") ||
        fileName.endsWith(".png") ||
        fileName.endsWith(".jpeg") ||
        fileName.endsWith(".if") ||
        fileName.endsWith(".gif")) {
        if (sizeImage > 10) {
          toast.error("Ảnh không vượt quá dung lượng 10MB", {
            position: toast.POSITION.TOP_RIGHT
          });
        } else {
          imageProvider.upload(event.target.files[0]).then(s => {

            if (s?.data.code == 0 && s?.data.data?.images?.length) {
              setImageModal(s?.data.data?.images[0].imageLink)
            } else {
              toast.error("Vui lòng thử lại !", {
                position: toast.POSITION.TOP_LEFT
              });
            }
          }).catch(e => {
          })
        }
      } else {
        toast.error("Vui lòng chọn đúng định dạng file ảnh", {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }
  const handleDeleteImage = (type) => {
    switch (type) {
      case 1:
        let image = '';
        if (inputElement.current) {
          inputElement.current.value = "";
        }
        setImageModal(image)
        break;
      default:
        break;
    }

  }
  const cancelModal = () => {
    setIsVisible(false)
  }
  const onOk = async () => {
    if (window.editor) {
      await window.editor.insertHtml(`<img alt='${alt || `''`}' src=${imageModal} />`);
      setImageModal('')
      setAlt('')
      cancelModal()
    }
  }

  return <div>
    <ToastContainer autoClose={3000} />
    <Provider store={store}>
      <App2 />
    </Provider>
    <Modal
      visible={isVisible}
      onCancel={cancelModal}
      style={{ padding: 10, flex: 1 }}
      onOk={onOk}
    >
      <div style={{ alignItems: 'center', margin: 10, padding: 5 }}>
        <Input
          type="text"
          placeholder="Chú thích ảnh..."
          required
          autoComplete="off"
          value={alt}
          onChange={event => {
            setAlt(event.target.value)
          }}
        />
        <Label className="label" htmlFor="text-input" style={{ width: 'auto', marginRight: 10, marginTop: 5 }}><strong>Ảnh</strong> (<span>*</span>): </Label>
        <div>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            className="upload-file"
            multiple
            id="upload_logo_header"
            onChange={(event) => {

              uploadImage(event)
            }}
            ref={inputElement}
            // ref={(input) => { this.inputElement = input }}
            type="file"
          />
          {
            !imageModal ?
              <label htmlFor="upload_logo_header" className="upload_logo_header" style={{ margin: 0 }}>
                <img style={{ width: 30, margin: 'auto', border: "1px soild" }}
                  src="/image-icon.png" alt='isofh_bvE' />
              </label>
              : <div style={{}} className='image-thumbnail'>
                <img style={{ objectFit: 'cover', borderRadius: 6 }}
                  src={imageModal.absoluteUrl()} alt='isofh_bvE' />
                <IconButton onClick={() => handleDeleteImage(1)} color="primary" style={{ position: 'absolute', top: 95, right: 30, cursor: 'pointer', }} aria-label="RemoveCircle">
                  <RemoveCircle />
                </IconButton>
              </div>
          }
        </div>
      </div>
    </Modal>
  </div>
}
function mapStateToProps(state) {
  return {
    userApp: state.userApp
  };
}

export default Kernel;