import { CaretDownOutlined } from '@ant-design/icons';
import { Spin, Table } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import { debounce, uniqBy } from 'lodash';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { toast } from 'react-toastify';
import { usePrevious } from '../../../../hooks/usePrevious';
import "./style.scss";

const TableMoveData = ({ columns, listDataTable, filter, callApi, onHeaderRow, rowSelection, className, moveItemApi }, ref) => {
    const pageDefault = 0;
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(20);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const prevFilter = usePrevious(filter);
    const [isMore, setIsMore] = useState(false);
    const [isLoadData, setIsLoadData] = useState(false);

    const debounceLoadData = useCallback(debounce((filter, page, size) => getData(filter, page, size), 300), []);

    useImperativeHandle(ref, () => ({
        reload: () => {
            getData(filter, page, size);
        },
        getData: () => {
            return data
        }
    }));

    useEffect(() => {
        if (isLoadData || (page === 0 && data.length == 0)) {
            getData(filter, page, size);
        } else if (filter != prevFilter) {
            debounceLoadData(filter, 0, size);
            setPage(0)
        }
    }, [page, filter, isLoadData]);

    const getData = async (filter, page, size) => {
        setLoading(true);
        let params = {
            page,
            size,
            ...filter
        };
        try {
            let res = await callApi(params);
            if (res) {
                setTotal(res?.totalPages);
                if (page === 0) {
                    setData(res.content);
                    listDataTable(res.content);
                } else {
                    let newOptions = uniqBy([...data, ...res.content]);
                    setData(newOptions);
                    listDataTable(newOptions);
                }
                setIsLoadData(false);
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            toast.error('Có lỗi xảy ra. Xin vui lòng thử lại !')
        }
    }

    // const onScroll = (event) => {
    //     var target = event.target;
    //     if (page <= total) {
    //         if (target.scrollTop + target.offsetHeight >= target.scrollHeight - 2) {
    //             if (page < (pageDefault === 0 ? total - 1 : total)) {
    //             }
    //         }
    //     }
    // };

    const dataSource = data?.map((item, i) => ({
        key: item?.id,
        index: i,
        stt: i + 1,
        ...item
    }));

    // const SortableItem = sortableElement((props) => <tr {...props} />);


    const SortableItem = sortableElement(({ children, ...props }) => {
        const childrenWithTd = React.Children.map(children, (child, index) => (
            <td className={`td-${child.key}`} key={index}>
                {child}
            </td>
        ));

        return (
            <tr {...props}>
                {childrenWithTd}
            </tr>
        );
    });

    const SortableContainer = sortableContainer((props) => <tbody {...props} />);
    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        const index = dataSource.findIndex((x) => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const DraggableContainer = (props) => (
        <SortableContainer useDragHandle helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
    );

    const moveDataAPI = async (id, prevItemId) => {
        setLoading(true);
        try {
            const params = {
                prev: prevItemId
            };
            let res = await moveItemApi(id, params);
            setLoading(false);
        } catch (error) {
            toast.error('Có lỗi xảy ra, xin vui lòng thử lại');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const onSortEnd = ({ oldIndex, newIndex }, props) => {
        if (oldIndex !== newIndex) {
            const itemId = data[oldIndex]?.id;
            if (dataSource) {
                const moveData = arrayMoveImmutable(dataSource, oldIndex, newIndex);
                if (moveData) {
                    let formatIndex = moveData.map((item, index) => {
                        return ({
                            ...item,
                            stt: index + 1
                        })
                    })
                    setData(formatIndex);
                    listDataTable(formatIndex);
                }
                const itemIndex = moveData.findIndex((item) => item.id === itemId);
                if (itemIndex !== -1) {
                    const prevItemId = itemIndex > 0 ? moveData[itemIndex - 1].id : null;
                    if (prevItemId) {
                        moveDataAPI(itemId, prevItemId);
                    } else {
                        moveDataAPI(itemId, -1);
                    }

                }
            }
        }
    };

    const renderTable = useMemo(
        () => (
            <div className="wrap-table">
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    onHeaderRow={onHeaderRow}
                    rowSelection={rowSelection}
                    className={className}
                    scroll={{ x: 900, y: 600 }}
                    rowKey="index"
                    components={{
                        body: {
                            wrapper: DraggableContainer,
                            row: DraggableBodyRow,
                        },
                    }}
                />
            </div>
        ),
        [dataSource]
    )

    const loadMoreData = () => {
        setPage(prev => prev + 1);
        setIsLoadData(true);
        setIsMore(false);
    }

    return (
        <div className='move-data-table'>
            <div className="wrap-table-data">
                <Spin size="large" tip="LOADING..." spinning={loading}>
                    {renderTable}
                    {page + 1 < total && (
                        <div
                            className="d-flex align-items-center justify-content-center cursor-pointer mt-1"
                            onClick={loadMoreData}
                        >
                            Xem thêm <CaretDownOutlined className="ml-2" />
                        </div>
                    )
                    }

                </Spin>
            </div>
        </div>
    );
}
export default forwardRef(TableMoveData);
