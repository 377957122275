import React from 'react';
import { Heading1 } from "./styled";


function HeadingPage({content}) {
  return (
    content && <Heading1>{content}</Heading1> || ""
  );
}

export default HeadingPage;