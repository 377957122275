import { Form, Input, Modal, Spin } from 'antd';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import './styles.scss';
import adminProvider from 'src/data-access/admin-provider';
import { toast } from 'react-toastify';

const ForgotPassword = (props, ref) => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    useImperativeHandle(ref, () => ({ onOpen }));

    const onOpen = () => {
        setVisible(true);
    };

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            const res = await adminProvider.forgotPassword({ "username": data?.username?.trim() });
            toast.success('Gửi yêu cầu quên mật khẩu thành công! Vui lòng kiểm tra email')
            setLoading(false);
            handleClose();
        } catch (error) {
            setLoading(false);
            if (error?.response?.data?.message) {
                toast.error(error.response.data.message, {});
                return;
            }
            toast.error("Có lỗi xảy ra. Xin vui lòng thử lại sau!");
        }
    }
    const handleClose = () => {
        form.resetFields();
        setVisible(false);
    }
    return (
        <Modal
            title='Đặt lại mật khẩu'
            visible={visible}
            onCancel={handleClose}
            footer={null}
            wrapClassName="modal-forgotPass"
        >
            <Spin size="large" tip="Đang gửi yêu cầu..." spinning={loading}>
                <Form
                    onFinish={onSubmit}
                    form={form}
                    layout="vertical"
                >
                    <Form.Item label='Tên đăng nhập' required name='username'
                        rules={[
                            { required: true, message: "Vui lòng nhập tên đăng nhập" }
                        ]}
                    >
                        <Input
                            placeholder='Nhập tên đăng nhập'
                            allowClear
                            maxLength={50}
                        />
                    </Form.Item>
                    <p className='text-link' style={{ textAlign: 'end' }} onClick={() => form.submit()}>Gửi yêu cầu</p>
                </Form>
            </Spin>
        </Modal>
    )
}

export default forwardRef(ForgotPassword);