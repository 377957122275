import React from "react";
import { Link, NavLink } from "react-router-dom";
import Collapse from "@kunukn/react-collapse";
import { Popover, Tooltip } from "antd";
import "./styles.scss";
import ItemMenuLv2 from "./ItemMenuLv2";
import { RightOutlined } from "@ant-design/icons";

function ItemMenu(props) {
  // const [state, _setState] = useState({
  //   show: false,
  // });
  // const setState = (_state) => {
  //   _setState((state) => ({
  //     ...state,
  //     ...(_state || {}),
  //   }));
  // };

  const onClick = (item) => (e) => {
    if (props.toggle) {
      props.toggle(item);
    }
    if (!item.href || item.href === "#") {
      e.preventDefault();
      e.stopPropagation();
    } else {
    }
  };

  const onActive = () => {
    props.setActive && props.setActive(props.index);
  };
  return (
    <li className={`nav-menu-lv1`}>
      {props.item.href && (!props.item.menus || !props.item.menus.length) ? (
        <Tooltip
          placement="right"
          title={props.item.name}
          overlayClassName="menu-less-tooltip"
        >
          <NavLink
            activeClassName="link-active"
            onClick={() => {
              onActive();
              onClick(props.item)();
            }}
            to={props.item.href}
            title={props.item.name}
            data-filter-tags={props.item.name + " " + props.item.filter}
            className=" waves-effect waves-themed"
          >
            <span className="nav-icon">
              <img src={props.item.icon} aria-roledescription="icon" />
            </span>
            <span className="nav-link-text" data-i18n={props.item.i18n}>
              {props.item.name}
            </span>
          </NavLink>
        </Tooltip>
      ) : (
        <Popover
          placement={props.index > 10 ? "rightBottom" : "rightTop"}
          title={
            <>
              <div className="popover-title">{props.item.name}</div>
              <a
                // href="#"
                className="toggle-sidebar-btn-link"
                // data-action="toggle"
                // data-class="nav-function-hidden"
                title="Togge Navigation"
                onClick={() => {
                  document.body.classList.toggle("nav-function-hidden");
                }}
              >
                <RightOutlined />
              </a>
            </>
          }
          overlayClassName="menu-less-popover"
          trigger="hover"
          content={
            <ul className="menu-hover">
              {props?.item?.menus?.map((item, index) => {
                let isAuthorized = false;
                const { userType = [] } = item;
                if (!userType.length) {
                  isAuthorized = true;
                } else {
                  const { authorities = [] } = props.auth;
                  isAuthorized = userType.some((item) =>
                    authorities.includes(item)
                  );
                }
                if (isAuthorized)
                  return (
                    <li key={index} onClick={onActive}>
                      <ItemMenuLv2
                        exact={item.exact}
                        to={item.href}
                        title={item.name}
                        className=" waves-effect waves-themed"
                        setOpen={() => {
                          if (props.toggle) {
                            props.toggle(props.item);
                          }
                        }}
                        setActive={props.setActive}
                        indexActive={props.index}
                        onActive={onActive}
                      >
                        <span
                          className="nav-link-text-hover"
                          data-i18n={item.i18n}
                        >
                          {item.name}
                        </span>
                      </ItemMenuLv2>
                    </li>
                  );
                return null;
              })}
            </ul>
          }
        >
          <a
            onClick={onClick(props.item)}
            href="#"
            title={props.item.name}
            data-filter-tags={props.item.name + " " + props.item.filter}
            className={` waves-effect waves-themed ${
              props.isActive ? "menus1-active" : ""
            }`}
          >
            <span className="nav-icon">
              <img src={props.item.icon} aria-roledescription="icon" />
            </span>
            <span className="nav-link-text" data-i18n={props.item.i18n}>
              {props.item.name}
            </span>
            <b className="collapse-sign">
              {props.item.open ? (
                <em className="fal fa-angle-up"></em>
              ) : (
                <em className="fal fa-angle-down"></em>
              )}
            </b>
          </a>
        </Popover>
      )}
      {props.item.menus && props.item.menus.length ? (
        <>
          <Collapse
            elementType="ul"
            isOpen={props.item.open}
            collapseHeight="0px"
            transition={`height 290ms cubic-bezier(.4, 0, .2, 1)`}
            className={`menu-collapse ${
              props.item.open ? "collapse-active" : ""
            }`}
          >
            {props.item.menus.map((item, index) => {
              let isAuthorized = false;
              const { userType = [] } = item;
              if (!userType.length) {
                isAuthorized = true;
              } else {
                const { authorities = [] } = props.auth;
                isAuthorized = userType.some((item) =>
                  authorities.includes(item)
                );
              }
              if (isAuthorized)
                return (
                  <li key={index} onClick={onActive}>
                    <ItemMenuLv2
                      to={item.href}
                      title={item.name}
                      className=" waves-effect waves-themed"
                      setOpen={() => {
                        if (props.toggle) {
                          props.toggle(props.item);
                        }
                      }}
                      setActive={props.setActive}
                      indexActive={props.index}
                      onActive={onActive}
                    >
                      <span className="nav-link-text" data-i18n={item.i18n}>
                        {item.name}
                      </span>
                    </ItemMenuLv2>
                  </li>
                );
              return null;
            })}
          </Collapse>
        </>
      ) : null}
    </li>
  );
}

export default ItemMenu;
