import constants from '../resources/strings';
import clientUtils from '../utils/client-utils';
import { combineUrlParams } from '../utils/helper';

export default {
    //Filters

    searchFilters({ page, size, name, note }) {
        let parameters = `?page=${page}&size=${size}` + (name ? '&name=' + name : '') + (note ? '&note=' + note : '');

        return new Promise((resolve, reject) => {
            clientUtils
                .requestApiFix('get', constants.api.notification.filters + parameters, {})
                .then((x) => {
                    resolve(x);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },

    createFilter(filter) {
        return new Promise((resolve, reject) => {
            clientUtils
                .requestApiFix('post', constants.api.notification.filters, filter)
                .then((x) => {
                    resolve(x);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },

    updateFilter(id, filter) {
        return new Promise((resolve, reject) => {
            clientUtils
                .requestApiFix('put', `${constants.api.notification.filters}/${id}`, filter)
                .then((x) => {
                    resolve(x);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },

    deleteFilter(id) {
        return new Promise((resolve, reject) => {
            clientUtils
                .requestApiFix('delete', `${constants.api.notification.filters}/${id}`)
                .then((x) => {
                    resolve(x);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },

    getDetailFilter(id) {
        return new Promise((resolve, reject) => {
            clientUtils
                .requestApiFix('get', `${constants.api.notification.filters}/${id}`)
                .then((x) => {
                    resolve(x);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },

    //Groups
    searchGroups({ page, size, name, content, schedule, status }) {
        let parameters =
            `?page=${page}&size=${size}` +
            (name ? '&name=' + name : '') +
            (content ? '&content=' + content : '') +
            (schedule ? '&schedule=' + schedule : '') +
            (status ? '&status=' + status : '');

        return new Promise((resolve, reject) => {
            clientUtils
                .requestApiFix('get', constants.api.notification.groups + parameters)
                .then((x) => {
                    resolve(x);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },

    createGroup(group) {
        return new Promise((resolve, reject) => {
            clientUtils
                .requestApiFix('post', constants.api.notification.groups, group)
                .then((x) => {
                    resolve(x);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },

    updateGroup(id, group) {
        return new Promise((resolve, reject) => {
            clientUtils
                .requestApiFix('put', `${constants.api.notification.groups}/${id}`, group)
                .then((x) => {
                    resolve(x);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },

    deleteGroup(id) {
        return new Promise((resolve, reject) => {
            clientUtils
                .requestApiFix('delete', `${constants.api.notification.groups}/${id}`)
                .then((x) => {
                    resolve(x);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },

    getDetailGroup(id) {
        return new Promise((resolve, reject) => {
            clientUtils
                .requestApiFix('get', `${constants.api.notification.groups}/${id}`)
                .then((x) => {
                    resolve(x);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },

    //events
    getEvents() {
        return new Promise((resolve, reject) => {
            clientUtils
                .requestApiFix('get', constants.api.notification.events, {})
                .then((x) => {
                    resolve(x);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },

    getEventRefs(listEndpoint) {
        return new Promise((resolve, reject) => {
            clientUtils
                .requestApiFix('get', listEndpoint)
                .then((x) => {
                    resolve(x);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    async getListNotification({ page, size, type }) {
        return await clientUtils.requestApiFix(
            'get',
            combineUrlParams(constants.api.notification.firebase_notification, {
                page,
                size,
                type,
            }),
        );
    },
    async watchNotification(notificationId) {
        return await clientUtils.requestApiFix(
            'put',
            `${constants.api.notification.watch_notification}/${notificationId}`,
        );
    },
    async getCountNotification() {
        return await clientUtils.requestApiFix('get', constants.api.notification.count_notification);
    },
    async watchAllNotification() {
        return await clientUtils.requestApiFix('put', `${constants.api.notification.watch_notification}`);
    },
    async exportExcel(params) {
        const url =
            constants.api.notification.export_excel.replace(':id', params.id) + (params?.watched ? `?watched=1` : '');
        return await clientUtils.requestApiExcel(
            'get',
            url,
            null,
            { 'Content-Type': 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet' },
            { responseType: 'arraybuffer' },
        );
    },
};
