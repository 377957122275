import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import LogoIcon from "./logo.svg"
import notificationProvider from '@data-access/notification-provider';

const ItemNotification = ({ item, index, onSetRef }) => {
  const time = useMemo(() => {
    const diffMinute = moment().diff(moment(item?.createdDate), 'minutes');
    let date = "";
    if (diffMinute === 0) {
      date = 'Vừa xong';
    }
    if (diffMinute > 0 && diffMinute < 60) {
      date = `${diffMinute} phút trước`
    }
    if (diffMinute > 60 && diffMinute < 1440) {
      date = `${Math.floor(diffMinute / 60)} giờ trước`
    }
    if (diffMinute > 1440) {
      date = moment(item?.createdDate).format("DD/MM/YYYY HH:mm")
    }
    return date;
  }, [item])

  const onView = useCallback((item) => async (e) => {
    try {
      e?.stopPropagation && e.stopPropagation();
      await notificationProvider.watchNotification(item?.id)
      const value = JSON.parse(item?.value);
      window.location = value?.directory;
    } catch (error) {
      console.log(error,'err')
    }
  },[item])

  return (
    <div onClick={onView(item)} className={`item ${!item?.watched && 'item-selected'}`} ref={onSetRef(index)}>
      <img src={LogoIcon} className="icon" />
      <div className="describe">
        <span className="title">
          {item?.title}
        </span>
        <span className="description">
          {item?.body}
        </span>
        <span className="date">
          {time}
        </span>
      </div>
    </div>
  )
}

export default React.memo(ItemNotification);
