import React, { useState } from 'react'
import { Image } from 'antd';
import styled from "styled-components";

const ListImages = (props) => {
    const { data } = props || []
    const [current, setCurrent] = useState(0)
    const [isPreviewVisible, setPreviewVisible] = useState(false);

    const renderImages = () => {
        if(!data || data?.length === 0) return
        if (data?.length <= 5) {
            switch (data?.length) {
                case 1:
                    return <Image src={data[0]} />
                case 2:
                return <BoxGrid2>
                        <Image src={data[0]} />
                        <Image src={data[1]} />
                </BoxGrid2>
                case 3:
                    return <BoxGrid3>
                        <Image src={data[0]} />
                        <Image src={data[1]} />
                        <Image src={data[2]} />
                    </BoxGrid3>
                case 4:
                    return <div>
                        <BoxGrid2>
                            <Image src={data[0]} />
                            <Image src={data[1]} />
                        </BoxGrid2>
                        <BoxGrid2>
                            <Image src={data[2]} />
                            <Image src={data[3]} />
                        </BoxGrid2>
                    </div>
                case 5:
                    return <div>
                        <BoxGrid2>
                            <Image src={data[0]} />
                            <Image src={data[1]} />
                        </BoxGrid2>
                        <BoxGrid3>
                            <Image src={data[2]} />
                            <Image src={data[3]} />
                            <Image src={data[4]} />
                        </BoxGrid3>
                    </div>
            }
        } else {
            return  <div>
                    <BoxGrid2>
                        <Image src={data[0]} />
                        <Image src={data[1]} />
                    </BoxGrid2>
                    <BoxGrid3>
                        <Image src={data[2]} />
                        <Image src={data[3]} />
                        <ImagePlus onClick={() => {
                            setCurrent(4)
                            setTimeout(setPreviewVisible(true), 500);
                        }}>
                            <Image src={data[4]} />
                            <BoxCount>
                                + {data?.length - 5}
                            </BoxCount>
                        </ImagePlus>
                        {data?.filter((item, index) => index > 4).map(item => <ImageHidden><Image src={item} /></ImageHidden>
                        )}
                    </BoxGrid3>
                </div>
        }
    }
    return (
        <PreviewGroupCustom>
            <Image.PreviewGroup
                preview={{
                    visible: isPreviewVisible,
                    onVisibleChange: (visible, prevVisible) => setPreviewVisible(visible),
                    onChange: (cur, prev) => setCurrent(cur),
                    current: current
                }}
            >
                {renderImages()}
            </Image.PreviewGroup>    
        </PreviewGroupCustom> 
    )
}
export default ListImages

const PreviewGroupCustom = styled.div`
    .ant-image {
        width: 100%;
        border-radius: 4px;
    }
    img {
        border-radius: 4px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .ant-image-mask-info {
        display: flex;
        align-items: center;
    }
`
const BoxGrid2 = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));100%;
    gap: 2px;
    margin-top: 4px;
    .ant-image {
        height: 170px;
    }
`
const BoxGrid3 = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));100%;
    gap: 2px;
    margin-top: 4px;
    .ant-image {
        height: 113px;
    }
`
const ImagePlus = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
`
const BoxCount = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000080;
    border-radius: 4px;
    font-size: 32px;
    color: white;
`
const ImageHidden = styled.div`
    display: none;
`