// Initialize the Firebase app in the service worker by passing the generated config
export const FIREBASE_CONFIG  = {
  config: {
    apiKey: "AIzaSyBdYm68fWgBgfRsvArkoSN4IT2TGuBIUvM",
    authDomain: "isofhcare.firebaseapp.com",
    databaseURL: "https://isofhcare.firebaseio.com",
    projectId: "isofhcare",
    storageBucket: "isofhcare.appspot.com",
    messagingSenderId: "553446981035",
    appId: "1:553446981035:web:8e1447dc17f88a361be6ee",
    measurementId: "G-ZHDZTKCS50"
  },
  vapidKey: 'BCzkgtJpNb3YnFGXYKN0vzeJAH6TYeShebOm4BIPTs5Ls8t0b6iGr9v0pxKbSq1LJ19oyb3CQLCXx-SdNBuUl98'
}