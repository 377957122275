import React, { useState, useEffect, useCallback } from "react";
import { InputWrapper } from "./styled";
import {
    SearchOutlined
} from "@ant-design/icons";
import { debounce } from "lodash";

function InputSearch({ placeholder, onChange, defaultValue, className, disablePrefix, maxLength = 50 }) {
    const [value, setValue] = useState(defaultValue);
    const debounceChangeData = useCallback(debounce(onChange, 300), []);

    useEffect(() => {
        if (defaultValue !== value) {
            setValue(defaultValue);
        }
    }, [defaultValue]);

    const onChangeValue = (e) => {
        const value = e.target.value;
        setValue(value);
        debounceChangeData(value)
    };

    return <InputWrapper
        placeholder={placeholder}
        onChange={onChangeValue}
        value={value}
        maxLength={maxLength}
        style={{ width: "100%" }}
        // prefix={disablePrefix ? null : <SearchOutlined />}
        className={className ? className : ""}
    />;
}

export default InputSearch;
