import styled from "styled-components";

export const NotPermisstionWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .p-heading {
    padding-bottom: 0.75rem;
  }

  .p-head {
    text-align: left;
    padding: 11px 12px;
  }

  .p-content {
    flex: 1 1;
    margin: 0 -0.75rem;
    padding: 0 0.75rem;
    background: #fafafa;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 0;

    @media screen and (min-width: 767px) {
      padding: 11rem 0;
    }

    img {
      width: 6.25rem;
      height: 6.25rem;
      max-width: 100%;
      max-height: 100%;
      margin-bottom: 20px;
    }

    p {
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: center;
      color: #2d3540;

      b {
        display: block;
      }
    }
  }
`;
