import React from "react";
import { Link, useRouteMatch } from "react-router-dom";

function ItemMenuLv2(props) {
  let match = useRouteMatch({
    path: props.to,
    exact: props.exact,
  });

  React.useEffect(() => {
    if (match) {
      props.onActive && props.onActive();
      // props.setOpen && props.setOpen();
    }
  }, []);

  return (
    <>
      <Link
        to={props.to}
        className={`${props.className} ${match ? "link-active" : ""}`}
      >
        {props.children}
      </Link>
    </>
  );
}

export default ItemMenuLv2;
