import accountIcon from '../../resources/images/sidebar/account_icon.svg';
import bookingIcon from '../../resources/images/sidebar/booking_icon.svg';
import marketingIcon from '../../resources/images/sidebar/marketing_icon.svg';
import questionIcon from '../../resources/images/sidebar/question_icon.svg';
import leadManagerIcon from '../../resources/images/sidebar/leadManager_icon.svg';
import reportIcon from '../../resources/images/sidebar/report_Icon.svg';
import configIcon from '../../resources/images/sidebar/config_icon.svg';
import contentIcon from '../../resources/images/sidebar/contentIcon.svg';
import dashboardIcon from '../../resources/images/sidebar/ic_dashboard.svg';
import levelCardIcon from '../../resources/images/sidebar/levelCardIcon.svg';
import teamIcon from '../../resources/images/sidebar/team_icon.svg';
import logIcon from '../../resources/images/sidebar/log.png';
import messageIcon from '../../resources/images/sidebar/message_icon.svg';

export const Menus = [
    {
        name: 'Dashboard',
        href: '/admin/dashboard',
        icon: dashboardIcon,
        filter: 'dashboard',
        authorities: ['ADMIN_MANAGER_DASHBOARD'],
        menus: [
            {
                name: 'Quản lý đặt khám',
                href: '/admin/dashboard/booking',
                exact: true,
                filter: 'medicine-store',
            },
            {
                name: 'User tracking',
                href: '/admin/dashboard/user-tracking',
                exact: true,
            },
            {
                name: 'Câu hỏi cộng đồng',
                href: '/admin/dashboard/questions',
                exact: true,
            },
        ],
    },
    {
        name: 'Quản lý tài khoản',
        href: '/admin/admin-manager',
        icon: accountIcon,
        filter: 'admin-manager',
        authorities: ['ADMIN_MANAGER_ADMIN'],
        menus: [
            {
                name: 'Tài khoản admin',
                href: '/admin/admin-list',
                filter: 'admin-manager-admin',
                exact: true,
            },
            {
                name: 'Danh sách vai trò',
                href: '/admin/admin-roles',
                filter: 'admin-manager-role',
                exact: true,
            },
        ],
    },
    {
        name: 'Quản lý đặt khám',
        href: '/admin/booking',
        icon: bookingIcon,
        filter: 'calendar-working',
        authorities: ['ADMIN_MANAGER_APPOINTMENT'],
        menus: [
            {
                name: 'Quản lý lịch đặt khám',
                href: '/admin/booking',
                filter: 'medicine-store',
                exact: true,
            },
            {
                name: 'Quản lý cuộc gọi',
                href: '/admin/calls',
                filter: 'booking-calls',
                exact: true,
            },
            {
                name: 'Quản lý thanh toán',
                href: '/admin/payment',
                filter: 'calendar-working',
                exact: true,
            },
        ],
    },
    {
        // role: [128],
        name: 'QL tài khoản người dùng',
        href: '/admin/doctor',
        icon: '/images/icon/user.svg',
        filter: 'doctor',
        authorities: ['ADMIN_MANAGER_LOG', 'ADMIN_MANAGER_USER'],
        menus: [
            {
                // role: [16],
                name: 'Tài khoản người dùng',
                href: '/admin/user',
                filter: 'sickness',
                exact: true,
                authorities: ['ADMIN_MANAGER_USER'],
            },
            {
                name: 'Tài khoản bác sĩ',
                href: '/admin/doctor',
                filter: 'doctor',
                exact: true,
                authorities: ['ADMIN_MANAGER_USER'],
            },
            {
                name: 'QL doanh nghiệp',
                href: '/admin/company',
                filter: 'company',
                exact: true,
                authorities: ['ADMIN_MANAGER_USER'],
            },
            {
                name: 'Người dùng doanh nghiệp',
                href: '/admin/person-company',
                filter: 'person-company',
                exact: true,
                authorities: ['ADMIN_MANAGER_USER'],
            },
        ],
    },
    {
        // role: [65536],
        name: 'Quản lý Cơ sở y tế',
        href: '/admin/hospitals',
        icon: '/images/icon/hospital.svg',
        filter: 'calendar-hospital',
        authorities: ['ADMIN_MANAGER_HOSPITAL'],
        menus: [
            {
                name: 'QL Cơ sở y tế',
                href: '/admin/hospitals',
                filter: 'calendar-hospital',
                exact: true,
            },
            {
                name: 'QL Chuyên khoa',
                href: '/admin/specialist',
                filter: 'special',
                exact: true,
            },
            {
                name: 'QL Duyệt dịch vụ',
                href: '/admin/service-type',
                filter: 'booking',
                exact: true,
            },
            {
                // role: [536870912],
                name: 'QL Danh mục dịch vụ',
                href: '/admin/category',
                filter: 'category',
                exact: true,
            },
        ],
    },
    {
        name: 'Quản lý nội dung',
        // href: "/admin",
        icon: contentIcon,
        filter: 'calendar-working',
        authorities: ['ADMIN_MANAGER_CONTENT'],
        menus: [
            // {
            //   // role: [128],
            //   name: "Tạo mới Danh mục",
            //   href: "/admin/create-category-news",
            //   filter: "doctor",
            // },
            {
                // role: [128],
                name: 'Danh mục bài viết',
                href: '/admin/news-category',
                filter: 'news',
                exact: true,
            },
            // {
            //   // role: [128],
            //   name: "Tạo mới bài viết",
            //   href: "/admin/create-news",
            //   filter: "doctor",
            // },
            {
                // role: [128],
                name: 'Bài viết',
                href: '/admin/news',
                filter: 'news',
                exact: true,
            },
        ],
    },
    {
        name: 'Quản lý marketing',
        icon: marketingIcon,
        filter: 'service',
        authorities: [
            'ADMIN_MANAGER_NOTIFICATION',
            'ADMIN_MANAGER_PROMOTION_VOUCHER',
            'ADMIN_CONSULTATION, ADMIN_MANAGER_SURVEY',
        ],
        menus: [
            {
                // role: [4194304],
                name: 'QL CT khuyến mãi',
                href: '/admin/list-service',
                filter: 'advertising-program',
                authorities: ['ADMIN_MANAGER_PROMOTION_VOUCHER'],
            },
            {
                name: 'Chương trình khuyến mãi',
                href: '/admin/marketing/promotion',
                authorities: ['ADMIN_MANAGER_PROMOTION_VOUCHER'],
            },
            {
                name: 'QL quà tặng',
                href: '/admin/marketing/gift',
                authorities: ['ADMIN_MANAGER_PROMOTION_VOUCHER'],
            },
            // {
            //     // role: [1024],
            //     name: "QL voucher",
            //     href: "/admin/voucher",
            //     filter: "calendar-working",
            //     authorities: ["ADMIN_MANAGER_PROMOTION_VOUCHER"],
            // },
            {
                name: 'Nhóm thông báo',
                href: '/admin/notification-filter',
                filter: 'advertising-program',
                authorities: ['ADMIN_MANAGER_NOTIFICATION'],
            },
            {
                name: 'Tin thông báo',
                href: '/admin/notification',
                filter: 'advertising-program',
                authorities: ['ADMIN_MANAGER_NOTIFICATION'],
            },
            {
                name: 'Game',
                href: '/admin/game',
                filter: 'advertising-program',
                authorities: ['ADMIN_MANAGER_PROMOTION_VOUCHER'],
            },
            // {
            //     name: "QL câu hỏi",
            //     href: "/admin/question",
            //     filter: "service",
            //     authorities: ["ADMIN_CONSULTATION"],
            // },
            {
                name: 'Danh sách khảo sát',
                href: '/admin/actual-survey',
                filter: 'actual-survey',
                // exact: true,
                authorities: ['ADMIN_MANAGER_SURVEY'],
            },
        ],
    },
    {
        name: 'Dữ liệu y khoa',
        href: '/admin/ICD',
        icon: '/images/icon/icd.svg',
        filter: 'calendar-working',
        authorities: ['ADMIN_MANAGER_MEDICAL_DATA'],
        menus: [
            {
                name: 'Mã ICD',
                href: '/admin/ICD',
                filter: 'calendar-working',
                exact: true,
            },
            {
                name: 'Nhóm xét nghiệm',
                href: '/admin/nhom-xet-nghiem',
                filter: 'calendar-working',
                exact: true,
            },
            {
                name: 'Từ điển Xét nghiệm',
                href: '/admin/tu-dien-xet-nghiem',
                filter: 'calendar-working',
                exact: true,
            },
            {
                name: 'Nhắc nhở uống thuốc',
                href: '/admin/remind-medicine',
                filter: 'remind-medicine-manage',
                exact: true,
            },
            {
                name: 'Chẩn đoán AI',
                href: '/admin/chan-doan-AI',
                filter: 'calendar-working',
                exact: true,
            },
        ],
    },
    {
        userType: [],
        href: '/admin/lead-manager',
        name: 'Quản lý Lead',
        icon: leadManagerIcon,
        filter: 'lead-manager',
        authorities: ['ADMIN_MANAGER_LEAD'],
    },
    // {
    //     name: 'Quản lý khảo sát',
    //     icon: teamIcon,
    //     filter: 'list-team',
    //     authorities: ['ADMIN_MANAGER_CONSULTATION_GROUP'],
    //     menus: [
    //         {
    //             name: 'Danh sách khảo sát',
    //             href: '/admin/actual-survey',
    //             filter: 'actual-survey',
    //             exact: true,
    //         },
    //     ]
    // },
    {
        name: 'Quản lý hội nhóm',
        icon: teamIcon,
        filter: 'list-team',
        authorities: ['ADMIN_MANAGER_CONSULTATION_GROUP'],
        menus: [
            {
                name: 'Danh sách hội nhóm',
                href: '/admin/list-team',
                filter: 'list-team',
                exact: true,
            },
        ],
    },
    // {
    //     userType: [],
    //     // href: '/admin/question',
    //     name: 'Quản lý app',
    //     // icon: questionIcon,
    //     icon: contentIcon,
    //     filter: 'question',
    //     authorities: ['ADMIN_CONSULTATION'],
    //      menus: [
    //         {
    //             name: 'Quản lý câu hỏi',
    //             href: '/admin/question',
    //             filter: 'question-manage',
    //             exact: true,
    //         },
    //         {
    //             name: 'Nhắc nhở uống thuốc',
    //             href: '/admin/remind-medicine',
    //             filter: 'remind-medicine-manage',
    //             exact: true,
    //         },
    //     ]
    // },
    {
        userType: [],
        href: '/admin/question',
        name: 'Quản lý câu hỏi',
        icon: questionIcon,
        filter: 'question',
        authorities: ['ADMIN_CONSULTATION'],
    },
    {
        name: 'Cấu hình phần mềm',
        href: '/admin/menu',
        icon: configIcon,
        filter: 'calendar-working',
        authorities: ['ADMIN_MANAGER_SETTING'],
        menus: [
            {
                name: 'Quản lý Menu',
                href: '/admin/menu',
                filter: 'calendar-working',
                exact: true,
            },
            {
                // role: [],
                name: 'Quản lý Slide item',
                href: '/admin/slide-item',
                filter: 'slide-item',
                exact: true,
            },
            {
                // role: [],
                name: 'Quản lý Slide',
                href: '/admin/slide',
                filter: 'doctor',
                exact: true,
            },
            {
                // role: [],
                name: 'Quản lý vị trí Slide',
                href: '/admin/slide-place',
                filter: 'doctor',
                exact: true,
            },
            {
                // role: [],
                name: 'Dịch vụ thế mạnh',
                href: '/admin/features',
                filter: 'calendar-working',
                exact: true,
            },
            {
                // role: [],
                name: 'Cấu hình nội dung trang chủ',
                href: '/admin/config-home',
                filter: 'config-home',
                exact: true,
            },
            {
                name: 'Quản lý triệu chứng',
                href: '/admin/symptom-management',
                filter: 'symptom-management',
                exact: true,
            },
            {
                name: 'Cấu hình chuyển hướng 301',
                href: '/admin/redirect-configs',
                filter: 'redirect-configs',
                exact: true,
            },
        ],
    },
    {
        name: 'ISOFHCARE support',
        href: '/admin/guide',
        icon: '/images/icon/setting.svg',
        filter: 'calendar-working',
        authorities: ['ADMIN_MANAGER_SETTING'],
        menus: [
            {
                name: 'Quản lý tài liệu HDSD',
                href: '/admin/guide',
                filter: 'calendar-working',
                exact: true,
            },
            {
                // role: [],
                name: 'Quản lý Q&A',
                href: '/admin/QandA',
                filter: 'doctor',
                exact: true,
            },
            {
                // role: [],
                name: 'Quản lý Video',
                href: '/admin/video',
                filter: 'doctor',
                exact: true,
            },
        ],
    },
    {
        name: 'Quản lý bán hàng',
        href: '/admin/Pharmacy',
        icon: accountIcon,
        filter: 'pharmacy',
        exact: true,
        authorities: ['ADMIN_MANAGER_PHARMACY'],
        menus: [
            {
                name: 'Quản lý merchant',
                href: '/admin/pharmacies',
                filter: 'pharmacies',
                exact: true,
            },
            {
                name: 'Danh mục sản phẩm',
                href: '/admin/pharmacy/medicine-group',
                exact: true,
            },
            {
                name: 'Danh sách sản phẩm',
                href: '/admin/pharmacy/medicines',
                exact: true,
            },
            {
                name: 'Quản lý đơn hàng',
                href: '/admin/pharmacy/orders',
                exact: true,
            },
            {
                name: 'Yêu cầu mua thuốc',
                href: '/admin/pharmacy/prescriptions',
                exact: true,
            },
            {
                name: 'Cấu hình vận chuyển',
                href: '/admin/pharmacy/delivery',
                exact: true,
            },
            {
                name: 'Quản lý bộ sưu tập',
                href: '/admin/pharmacy/album-hotDeal',
                exact: true,
            },
            {
                name: 'Quản lý chat mua sắm',
                href: '/admin/pharmacy/chat-history',
                exact: true,
            },
            // {
            //     name: 'Quản lý Hot Deal',
            //     href: '/admin/pharmacy/hotdeal',
            //     exact: true,
            // },
        ],
    },
    {
        name: 'Tích điểm',
        href: '/admin/accumulatePoints',
        icon: levelCardIcon,
        filter: 'accumulatePoints',
        exact: true,
        authorities: ['ADMIN_MANAGER_PROMOTION_VOUCHER'],
        menus: [
            {
                name: 'Quản lý thẻ thành viên',
                href: '/admin/membership',
                filter: 'membership',
                exact: true,
            },
            {
                name: 'Quản lý hạng thẻ',
                href: '/admin/accumulatePoints/levelCard',
                filter: 'accumulatePoints',
                exact: true,
            },
            {
                name: 'Cấu hình',
                href: '/admin/earn-point-config',
                filter: 'earn-point-config',
                exact: true,
            },
            {
                name: 'Quản lý quy đổi điểm',
                href: '/admin/redeem-points',
                filter: 'redeemPoints',
                exact: true,
            },
        ],
    },
    {
        name: 'Quản lý tiện ích',
        href: '/admin/convenients',
        icon: accountIcon,
        filter: 'convenients',
        exact: true,
        authorities: ['ADMIN_MANAGER_CATALOG'],
        menus: [
            {
                name: 'Dịch vụ tiện ích',
                href: '/admin/convenients',
                filter: 'convenients',
                exact: true,
            },
            {
                name: 'Quản lý gói gọi khẩn cấp',
                href: '/admin/convenient-transactions',
                filter: 'convenients',
                exact: true,
            },
            {
                name: 'Quản lý cuộc gọi khẩn cấp',
                href: '/admin/urgent-calls',
                filter: 'convenients',
                exact: true,
            },
            {
                name: 'Giao dịch tiện ích',
                href: '/admin/convenient-transaction-chat',
                filter: 'convenients',
                exact: true,
            },
            {
                name: 'Quản lý phiên chat riêng',
                href: '/admin/convenient-session-chat',
                filter: 'convenients',
                exact: true,
            },
        ],
    },
    {
        name: 'Báo cáo',
        href: '/admin/revenue',
        icon: reportIcon,
        filter: 'calendar-working',
        authorities: ['ADMIN_MANAGER_REPORT'],
        exact: true,
        menus: [
            {
                name: 'Báo cáo đặt khám',
                href: '/admin/report',
                filter: 'report-manage',
                exact: true,
            },
            {
                name: 'Báo cáo tiện ích',
                href: '/admin/utilities',
                filter: 'report-utilities',
                exact: true,
            },
            {
                name: 'Báo cáo lead',
                href: '/admin/lead-report',
                filter: 'lead-report',
                exact: true,
            },
            {
                name: 'Báo cáo đơn hàng',
                href: '/admin/reportOrder',
                filter: '/reportOrder',
                exact: true,
            },
            {
                name: 'Báo cáo tổng quan chất lượng bác sĩ',
                href: '/admin/report-doctor-quality',
                filter: 'report-order',
                exact: true,
            },
            {
                name: 'Báo cáo đánh giá đặt khám',
                href: '/admin/report-servey',
                filter: 'report-servey',
                exact: true,
            },
            {
                name: 'Báo cáo user tracking',
                filter: 'report-tracking',
                href: '/admin/user-tracking',
                exact: true,
            },
        ],
    },
    {
        userType: [],
        href: '/admin/logs',
        name: 'Quản lý log',
        icon: logIcon,
        authsorities: ['ADMIN_MANAGER_LOG'],
        exact: true,
        menus: [
            {
                name: 'Quản lý log Admin',
                href: '/admin/logs',
                filter: 'logs',
                exact: true,
                authorities: ['ADMIN_MANAGER_LOG'],
            },
            {
                name: 'Quản lý log tìm kiếm App/web user',
                href: '/admin/logs-search',
                filter: 'report-search',
                authorities: ['ADMIN_MANAGER_LOG'],
                exact: true,
            },
        ],
    },
    {
        name: 'Quản lý SMS/ZNS',
        href: '/admin/quan-ly-tin-nhan',
        icon: messageIcon,
        filter: 'message-manager',
        authorities: ['ADMIN_MANAGER_NOTIFICATION'],
        exact: true,
    },
];
