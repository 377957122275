import React from 'react';
import styled from "styled-components";
import { Button, Select, DatePicker } from "antd";


export const ButtonSearch = styled(Button)`
  .display-none {
    display: none !important;
  }
`;

export const ButtonFilter = styled(Button)`
  border-radius: 4px 0px 0px 4px;
  background-color: rgba(10, 155, 225, 0.1);
`;


export const SelectCustom = styled(Select).attrs(props => ({
  suffixIcon: !props?.loading ? (
      <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.2946 0.294459C10.9053 -0.0948134 10.2743 -0.0951574 9.88462 0.293691L6.70634 3.46505C6.31599 3.85454 5.68401 3.85454 5.29366 3.46505L2.11538 0.29369C1.72569 -0.0951576 1.09466 -0.0948134 0.705384 0.294459C0.315811 0.684032 0.315811 1.31565 0.705384 1.70523L5.29289 6.29274C5.68342 6.68326 6.31658 6.68326 6.70711 6.29274L11.2946 1.70523C11.6842 1.31565 11.6842 0.684032 11.2946 0.294459Z" fill="#101011"/>
      </svg>    
  ) : undefined,
  size: 'default'
}))`
  .ant-select-selector {
      border-radius: 4px !important;
  }
  .ant-select-selection-item {
    display: block !important;
    flex: 0 1 auto;
  }
  .ant-select-selection-placeholder {
    width: calc(100% - 40px)
  }
`

export const DateRangePickerCustom = styled(DatePicker.RangePicker).attrs(props => ({
  suffixIcon: !props?.loading ? (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.43493 6.55095C4.43493 6.41733 4.32655 6.30908 4.19282 6.30908H3.34794C3.21436 6.30908 3.10596 6.41733 3.10596 6.55095V7.39573C3.10596 7.52952 3.21436 7.63782 3.34794 7.63782H4.19282C4.32655 7.63782 4.43493 7.52952 4.43493 7.39573V6.55095Z" fill="#69788C"/>
    <path d="M6.54613 6.55095C6.54613 6.41733 6.43773 6.30908 6.30426 6.30908H5.45927C5.32569 6.30908 5.21729 6.41733 5.21729 6.55095V7.39573C5.21729 7.52952 5.32569 7.63782 5.45927 7.63782H6.30426C6.43773 7.63782 6.54613 7.52952 6.54613 7.39573V6.55095Z" fill="#69788C"/>
    <path d="M8.65856 6.55095C8.65856 6.41733 8.55016 6.30908 8.41658 6.30908H7.5717C7.43797 6.30908 7.32959 6.41733 7.32959 6.55095V7.39573C7.32959 7.52952 7.43797 7.63782 7.5717 7.63782H8.41658C8.55016 7.63782 8.65856 7.52952 8.65856 7.39573V6.55095Z" fill="#69788C"/>
    <path d="M4.43493 8.66241C4.43493 8.52857 4.32655 8.42041 4.19282 8.42041H3.34794C3.21436 8.42041 3.10596 8.52857 3.10596 8.66241V9.50703C3.10596 9.64074 3.21436 9.74901 3.34794 9.74901H4.19282C4.32655 9.74901 4.43493 9.64072 4.43493 9.50703V8.66241Z" fill="#69788C"/>
    <path d="M6.54613 8.66241C6.54613 8.52857 6.43773 8.42041 6.30426 8.42041H5.45927C5.32569 8.42041 5.21729 8.52857 5.21729 8.66241V9.50703C5.21729 9.64074 5.32569 9.74901 5.45927 9.74901H6.30426C6.43773 9.74901 6.54613 9.64072 6.54613 9.50703V8.66241Z" fill="#69788C"/>
    <path d="M8.65856 8.66241C8.65856 8.52857 8.55016 8.42041 8.41669 8.42041H7.5717C7.43797 8.42041 7.32959 8.52857 7.32959 8.66241V9.50703C7.32959 9.64074 7.43797 9.74901 7.5717 9.74901H8.41669C8.55016 9.74901 8.65856 9.64072 8.65856 9.50703V8.66241Z" fill="#69788C"/>
    <path d="M10.1689 1.85424V3.14456C10.1689 3.72777 9.69576 4.19775 9.11264 4.19775H8.44632C7.86316 4.19775 7.38376 3.72777 7.38376 3.14456V1.84961H4.38052V3.14456C4.38052 3.72777 3.90114 4.19775 3.31806 4.19775H2.65162C2.06851 4.19775 1.59541 3.72777 1.59541 3.14456V1.85424C1.08577 1.8696 0.666992 2.29131 0.666992 2.80965V10.3715C0.666992 10.8996 1.09502 11.3333 1.6231 11.3333H10.1412C10.6685 11.3333 11.0973 10.8987 11.0973 10.3715V2.80965C11.0973 2.29131 10.6785 1.8696 10.1689 1.85424ZM9.85941 9.90064C9.85941 10.1289 9.67437 10.314 9.44607 10.314H2.30001C2.0717 10.314 1.88667 10.1289 1.88667 9.90064V5.99465C1.88667 5.76635 2.07168 5.5812 2.30001 5.5812H9.44604C9.67435 5.5812 9.85938 5.76635 9.85938 5.99465L9.85941 9.90064Z" fill="#69788C"/>
    <path d="M2.64985 3.50597H3.30893C3.50898 3.50597 3.67118 3.34401 3.67118 3.14396V1.02865C3.67118 0.828575 3.50898 0.666504 3.30893 0.666504H2.64985C2.44978 0.666504 2.2876 0.828575 2.2876 1.02865V3.14396C2.2876 3.34401 2.44978 3.50597 2.64985 3.50597Z" fill="#69788C"/>
    <path d="M8.43791 3.50597H9.09699C9.2969 3.50597 9.4591 3.34401 9.4591 3.14396V1.02865C9.45913 0.828575 9.29692 0.666504 9.09699 0.666504H8.43791C8.23788 0.666504 8.07568 0.828575 8.07568 1.02865V3.14396C8.07568 3.34401 8.23788 3.50597 8.43791 3.50597Z" fill="#69788C"/>
    </svg>    
  ) : undefined
}))`
  border-radius: 4px;
`
export const DatePickerCustom = styled(DatePicker).attrs(props => ({
  suffixIcon: !props?.loading ? (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.43493 6.55095C4.43493 6.41733 4.32655 6.30908 4.19282 6.30908H3.34794C3.21436 6.30908 3.10596 6.41733 3.10596 6.55095V7.39573C3.10596 7.52952 3.21436 7.63782 3.34794 7.63782H4.19282C4.32655 7.63782 4.43493 7.52952 4.43493 7.39573V6.55095Z" fill="#69788C"/>
    <path d="M6.54613 6.55095C6.54613 6.41733 6.43773 6.30908 6.30426 6.30908H5.45927C5.32569 6.30908 5.21729 6.41733 5.21729 6.55095V7.39573C5.21729 7.52952 5.32569 7.63782 5.45927 7.63782H6.30426C6.43773 7.63782 6.54613 7.52952 6.54613 7.39573V6.55095Z" fill="#69788C"/>
    <path d="M8.65856 6.55095C8.65856 6.41733 8.55016 6.30908 8.41658 6.30908H7.5717C7.43797 6.30908 7.32959 6.41733 7.32959 6.55095V7.39573C7.32959 7.52952 7.43797 7.63782 7.5717 7.63782H8.41658C8.55016 7.63782 8.65856 7.52952 8.65856 7.39573V6.55095Z" fill="#69788C"/>
    <path d="M4.43493 8.66241C4.43493 8.52857 4.32655 8.42041 4.19282 8.42041H3.34794C3.21436 8.42041 3.10596 8.52857 3.10596 8.66241V9.50703C3.10596 9.64074 3.21436 9.74901 3.34794 9.74901H4.19282C4.32655 9.74901 4.43493 9.64072 4.43493 9.50703V8.66241Z" fill="#69788C"/>
    <path d="M6.54613 8.66241C6.54613 8.52857 6.43773 8.42041 6.30426 8.42041H5.45927C5.32569 8.42041 5.21729 8.52857 5.21729 8.66241V9.50703C5.21729 9.64074 5.32569 9.74901 5.45927 9.74901H6.30426C6.43773 9.74901 6.54613 9.64072 6.54613 9.50703V8.66241Z" fill="#69788C"/>
    <path d="M8.65856 8.66241C8.65856 8.52857 8.55016 8.42041 8.41669 8.42041H7.5717C7.43797 8.42041 7.32959 8.52857 7.32959 8.66241V9.50703C7.32959 9.64074 7.43797 9.74901 7.5717 9.74901H8.41669C8.55016 9.74901 8.65856 9.64072 8.65856 9.50703V8.66241Z" fill="#69788C"/>
    <path d="M10.1689 1.85424V3.14456C10.1689 3.72777 9.69576 4.19775 9.11264 4.19775H8.44632C7.86316 4.19775 7.38376 3.72777 7.38376 3.14456V1.84961H4.38052V3.14456C4.38052 3.72777 3.90114 4.19775 3.31806 4.19775H2.65162C2.06851 4.19775 1.59541 3.72777 1.59541 3.14456V1.85424C1.08577 1.8696 0.666992 2.29131 0.666992 2.80965V10.3715C0.666992 10.8996 1.09502 11.3333 1.6231 11.3333H10.1412C10.6685 11.3333 11.0973 10.8987 11.0973 10.3715V2.80965C11.0973 2.29131 10.6785 1.8696 10.1689 1.85424ZM9.85941 9.90064C9.85941 10.1289 9.67437 10.314 9.44607 10.314H2.30001C2.0717 10.314 1.88667 10.1289 1.88667 9.90064V5.99465C1.88667 5.76635 2.07168 5.5812 2.30001 5.5812H9.44604C9.67435 5.5812 9.85938 5.76635 9.85938 5.99465L9.85941 9.90064Z" fill="#69788C"/>
    <path d="M2.64985 3.50597H3.30893C3.50898 3.50597 3.67118 3.34401 3.67118 3.14396V1.02865C3.67118 0.828575 3.50898 0.666504 3.30893 0.666504H2.64985C2.44978 0.666504 2.2876 0.828575 2.2876 1.02865V3.14396C2.2876 3.34401 2.44978 3.50597 2.64985 3.50597Z" fill="#69788C"/>
    <path d="M8.43791 3.50597H9.09699C9.2969 3.50597 9.4591 3.34401 9.4591 3.14396V1.02865C9.45913 0.828575 9.29692 0.666504 9.09699 0.666504H8.43791C8.23788 0.666504 8.07568 0.828575 8.07568 1.02865V3.14396C8.07568 3.34401 8.23788 3.50597 8.43791 3.50597Z" fill="#69788C"/>
    </svg>    
  ) : undefined
}))`
  border-radius: 4px;
`

export const IconTrash = () => {
  return (
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.33333 14C1.90556 14 1.53935 13.8477 1.23472 13.5431C0.930093 13.2384 0.777778 12.8722 0.777778 12.4444V2.33333H0V0.777778H3.88889V0H8.55556V0.777778H12.4444V2.33333H11.6667V12.4444C11.6667 12.8722 11.5144 13.2384 11.2097 13.5431C10.9051 13.8477 10.5389 14 10.1111 14H2.33333ZM10.1111 2.33333H2.33333V12.4444H10.1111V2.33333ZM3.88889 10.8889H5.44444V3.88889H3.88889V10.8889ZM7 10.8889H8.55556V3.88889H7V10.8889Z" fill="#69788C"/>
    </svg>
  )
}

export const IconSearch = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.6583 11.4083H12L11.6583 11.0667C12.7 9.9 13.3333 8.35833 13.3333 6.66667C13.3333 2.98333 10.35 0 6.66667 0C2.98333 0 0 2.98333 0 6.66667C0 10.35 2.98333 13.3333 6.66667 13.3333C8.35833 13.3333 9.9 12.7 11.075 11.6583L11.4083 12V12.6583L13.7583 15L15 13.7583L12.6583 11.4083ZM6.66667 11.6667C3.90833 11.6667 1.66667 9.425 1.66667 6.66667C1.66667 3.90833 3.90833 1.66667 6.66667 1.66667C9.425 1.66667 11.6667 3.90833 11.6667 6.66667C11.6667 9.425 9.425 11.6667 6.66667 11.6667Z" fill="white"/>
      <path d="M12.6583 11.4083H12L11.6583 11.0667C12.7 9.9 13.3333 8.35833 13.3333 6.66667C13.3333 2.98333 10.35 0 6.66667 0C2.98333 0 0 2.98333 0 6.66667C0 10.35 2.98333 13.3333 6.66667 13.3333C8.35833 13.3333 9.9 12.7 11.075 11.6583L11.4083 12V12.6583L13.7583 15L15 13.7583L12.6583 11.4083ZM6.66667 11.6667C3.90833 11.6667 1.66667 9.425 1.66667 6.66667C1.66667 3.90833 3.90833 1.66667 6.66667 1.66667C9.425 1.66667 11.6667 3.90833 11.6667 6.66667C11.6667 9.425 9.425 11.6667 6.66667 11.6667Z" fill="white"/>
    </svg>
  )
}

