import { Form, Select } from 'antd';
import React from 'react';
import SelectGetAPI from '../SelectGetAPI';
import './styles.scss';

function FormSelect({
  name,
  label = name,
  placeholder = 'Chọn',
  required = false,
  message = `Vui lòng chọn trường này`,
  options = [],
  defaultValue,
  valueDefault,
  value = 'value',
  mode,
  dropdownRender,
  className,
  isSelectAPI,
  keySearch,
  callback,
  optionText,
  disabled,
  onChange,
  displaySelect,
  showSearch = false,
  apiUrl,
  allowClear = false,
  nameOption = (item) => item?.name,
  valueOption = (item) => item?.id,
  maxSelected,
  optionsSelected = [],
}) {
  function removeDiacritics(str) {
    str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    str = str.replace(/đ/g, 'd').replace(/Đ/g, 'D');
    return str;
  }
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required,
          message,
        },
      ]}
      className="form-select"
    >
      {isSelectAPI ? (
        <SelectGetAPI
          nameOption={nameOption}
          valueOption={valueOption}
          placeholder={placeholder}
          name={name}
          searchName="name"
          firstPage={0}
          displaySelect={displaySelect}
          size={20}
          apiUrl={apiUrl}
          value={valueDefault}
          set={(name, value) => onChange && onChange(name, value)}
          keySearch={keySearch}
          allowClear={allowClear}
          dropdownMatchSelectWidth={false}
          search={true}
        ></SelectGetAPI>
      ) : (
        <Select
          dropdownRender={dropdownRender}
          mode={mode}
          allowClear={allowClear}
          onChange={(value) => onChange && onChange(name, value)}
          placeholder={placeholder}
          filterOption={(input, option) =>
            removeDiacritics(option.children.toLowerCase()).indexOf(
              removeDiacritics(input.toLowerCase()),
            ) >= 0
          }
          value={valueDefault}
          // defaultValue={defaultValue}
          className={`${className}`}
          disabled={disabled}
          showSearch={showSearch}
        >
          {options.map((option) => (
            <Select.Option
              disabled={
                optionsSelected && optionsSelected.length >= maxSelected
                  ? optionsSelected.includes(option[value])
                    ? false
                    : true
                  : false
              }
              key={option[value]}
              value={option[value]}
            >
              {optionText ? optionText(option) : option.name}
            </Select.Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
}

export default FormSelect;
