import { CheckOutlined, DragOutlined, LoadingOutlined } from '@ant-design/icons';
import {
    DndContext,
    KeyboardSensor,
    PointerSensor,
    closestCenter,
    useDraggable,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    SortableContext,
    arrayMove,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Checkbox, Modal } from 'antd';
import _ from 'lodash';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import resetSetting from '../../../../../resources/images/setting__reset.svg';
import { InputSearch } from '../../../components';
import './styles.scss';

function ModalSetting({ data, onSaveSetting, columnsDefault }, ref) {
    const [visible, setVisible] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [dataTable, setDataTable] = useState(data);
    const [dataFilter, setDataFilter] = useState(data);

    useEffect(() => {
        if (Array.isArray(data) && data?.length > 0) {
            const defaultData = _.cloneDeep(data);
            setDataTable([...defaultData.filter((item) => item?.isShow), ...data.filter((item) => !item?.isShow)]);
        } else {
            setDataTable([]);
        }
    }, [data]);

    useImperativeHandle(ref, () => ({
        onOpen: () => {
            handleOpen();
            setSearchValue('');
            if (Array.isArray(data) && data?.length > 0) {
                const defaultData = _.cloneDeep(data);
                setDataTable([...defaultData.filter((item) => item?.isShow), ...data.filter((item) => !item?.isShow)]);
            } else {
                setDataTable([]);
            }
        },
    }));

    const handleReset = () => {
        let newColumnsDefault = _.cloneDeep(columnsDefault);
        onSaveSetting(newColumnsDefault.slice(1, newColumnsDefault.length - 1));
        setVisible(false);
    };

    const handleClose = () => {
        setVisible(false);
    };

    const handleOpen = () => {
        setVisible(true);
    };

    const onSearch = (value) => {
        setSearchValue(value);
        let newData = data.filter((item, index) => {
            if (
                item.title
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .indexOf(
                        value
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, ''),
                    ) >= 0
            ) {
                return item;
            }
        });
        setDataFilter(newData);
    };

    const onChangeListChecked = (key, checked) => {
        let newDataTable = [...dataTable];
        let indexKey = newDataTable.findIndex((item) => item.key == key);
        if (indexKey > -1) {
            if (checked) {
                newDataTable[indexKey].isShow = true;
            } else {
                newDataTable[indexKey].isShow = false;
            }
        }
        if (searchValue) {
            setDataFilter(newDataTable.filter((item) => dataFilter.findIndex((it) => it.key == item.key) > -1));
        }
        setDataTable(newDataTable);
    };

    const onSave = (dataTableNew) => {
        onSaveSetting(dataTableNew);
        handleClose();
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;
        setDataTable((prev) => {
            return arrayMove(prev, active.id - 1, over.id - 1);
        });
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );
    const DragHandle = (props) => {
        const { attributes, listeners, setNodeRef } = useDraggable({
            id: props.id,
        });
        return (
            <div ref={setNodeRef}>
                <DragOutlined
                    style={{ cursor: 'grab', color: '#69788C', fontSize: '18px' }}
                    {...listeners}
                    {...attributes}
                />
            </div>
        );
    };
    const SortableItem = ({ value, id, section }) => {
        const item = value.value;
        const index = value.index;
        const { setNodeRef, transform, transition, active, isDragging } = useSortable({ id: id });

        const style = {
            transform: CSS.Translate.toString(transform),
            transition,
            ...(active
                ? {
                      position: 'relative',
                      zIndex: isDragging ? 2 : undefined,
                  }
                : {}),
        };
        return (
            <div
                key={index + item.toString()}
                ref={setNodeRef}
                style={style}
                className={`row-item ${item?.isShow ? 'is-show' : ''}`}
            >
                <Checkbox
                    name={item.key}
                    checked={item.isShow}
                    onChange={(e) => {
                        onChangeListChecked(item.key, e.target.checked);
                    }}
                >
                    <div className="row-content">{item.title}</div>
                </Checkbox>
                <DragHandle id={id} />
            </div>
        );
    };

    return (
        <Modal
            title={'Cài đặt hiển thị bảng'}
            visible={visible}
            onCancel={handleClose}
            footer={null}
            wrapClassName="modal-setting-table"
        >
            <div className="md-setting__body">
                <div className="title">Check vào tên cột muốn hiển thị và kéo thả vào vị trí muốn hiển thị</div>
                <div className="input-search">
                    <InputSearch
                        placeholder="Tìm tên"
                        defaultValue={searchValue}
                        onChange={(value) => onSearch(value)}
                    />
                </div>
                <div className="list-filter">
                    <DndContext sensors={sensors} onDragEnd={handleDragEnd} collisionDetection={closestCenter}>
                        <SortableContext
                            items={(searchValue ? dataFilter : dataTable).map((item, index) => index + 1)}
                            strategy={verticalListSortingStrategy}
                        >
                            {(searchValue ? dataFilter : dataTable)?.map((section, index) => (
                                <SortableItem
                                    // key={section?.id}
                                    id={index + 1}
                                    value={{
                                        value: section,
                                        index: index,
                                    }}
                                    section={section}
                                />
                            ))}
                        </SortableContext>
                    </DndContext>
                </div>
                <div className="btns-style actions">
                    <Button
                        onClick={handleReset}
                        className="close"
                        // icon={resetSetting}
                    >
                        <img src={resetSetting} className="mr-2" />
                        Về mặc định
                    </Button>
                    <Button
                        onClick={() => onSave(dataTable)}
                        className={`${loading ? 'loading' : ''}`}
                        type="primary"
                        htmlType="submit"
                        icon={!loading ? <CheckOutlined /> : <LoadingOutlined />}
                        style={{ backgroundColor: '#0A9BE1' }}
                    >
                        Lưu lại
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default forwardRef(ModalSetting);
