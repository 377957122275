import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NotPermisstion } from "../../../components";
import { Steps, Col, Row, Table, Button, Image } from 'antd';
import pharmacyProvider from "@data-access/pharmacy-provider";
import { prescriptionStatus, prescriptionEvents } from '../constants';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
    CloseOutlined
} from "@ant-design/icons";
import './styles.scss';
import { useMemo } from 'react';
import * as moment from 'moment';
import { isImage } from '@utils/common';
const { Step } = Steps;

function PrescriptionDetail() {
    let { id } = useParams();
    const [detail, setDetail] = useState({});
    const [detailUser, setDetailUser] = useState({});
    const currentUser = useSelector((state) => state.userApp.currentUser);
    const isHasPermission = currentUser?.authorities?.indexOf('ADMIN') > -1 || currentUser?.authorities?.indexOf('ADMIN_MANAGER_PHARMACY') > -1;

    useEffect(() => {
        getDetail()
    }, []);

    const getDetail = async () => {
        if (id) {
            try {
                pharmacyProvider
                    .getPrescriptionsDetail(id)
                    .then((res) => {
                        if (res) {
                            setDetail(res);
                        } else {
                            toast.error("Có lỗi xảy ra, xin vui lòng thử lại!");
                            setDetail({});
                        }
                    })
            } catch (error) {
                toast.error("Có lỗi xảy ra, xin vui lòng thử lại!")
            }
        }
    }

    const getDetailUser = async () => {
        if (detail && detail?.createdBy) {
            try {
                pharmacyProvider
                    .getPrescriptionsDetailUser(detail?.createdBy)
                    .then((res) => {
                        if (res) {
                            setDetailUser(res)
                        } else {
                            toast.error("Có lỗi xảy ra, xin vui lòng thử lại!");
                            setDetailUser({})
                        }
                    })
            } catch (error) {
                toast.error("Có lỗi xảy ra, xin vui lòng thử lại!")
            }
        }
    }

    useEffect(() => {
        getDetailUser();
    }, [detail?.createdBy])

    const renderHeader = () => {
        return (
            <div className='prescriptions-detail__header'>
                <div className='title'>Chi tiết yêu cầu mua thuốc</div>
                <div className='btn-back'>
                    <Link to={`/admin/pharmacy/prescriptions`}>
                        <Button
                            className="btn-close"
                            icon={<CloseOutlined />}
                        >Quay lại</Button>
                    </Link>
                </div>
            </div>
        )
    }

    const renderPrescriptionStatus = () => {
        let statusValue = prescriptionStatus?.find(item => item?.id == detail?.status);
        return (
            <div className="prescriptions-detail__item">
                <div className="label">Trạng thái</div>
                <div className={statusValue?.className}>{statusValue?.name}</div>
            </div>
        )
    }

    const customDot = (dot, { status, index }) => {
        return dot;
    };

    const listImagesFind = useMemo(() => {
        if (!Array.isArray(detail?.metadata?.files)) {
            return [];
        };
        return detail?.metadata?.files.filter((item) => isImage(item?.pathOriginal)); //(item) => item?.pathOriginal


    }, [detail?.metadata?.files])
    const renderBody = () => {
        let dataSource = detail?.metadata?.medicines ? detail?.metadata?.medicines?.map((item, index) => {
            return {
                STT: index + 1,
                name: item,
                quantity: item,
                unit: item?.unit,
            }
        }) : [];
        let listImages = detail?.metadata?.files;
        const columns = [
            {
                title: 'STT',
                dataIndex: 'STT',
                key: 'stt',
                align: "center"
            },
            {
                title: 'Tên thuốc',
                dataIndex: 'name',
                key: 'name',
                width: "60%",
                render: (item) => {
                    return <>{item?.name} {item?.content}</>
                }
            },
            {
                title: 'Số lượng',
                dataIndex: 'quantity',
                key: 'quantity',
                width: "20%",
                render: (item) => {
                    return <>{item?.quantity} {item?.unit}</>
                }
            },
        ]

        const onPhone = (phone) => {
            global.CALLIO_API.makeCall(phone)
        }
        return (
            <div className='prescriptions-detail__body'>
                <div className='prescriptions-detail__wrapper'>
                    <div className='prescriptions-detail__main-info'>
                        <Row gutter={[24, 0]}>
                            <Col sm={12} xs={24}>
                                {renderPrescriptionStatus()}
                            </Col>
                            <Col sm={12} xs={24}>
                                <div className="prescriptions-detail__item">
                                    <div className="label">Mã đơn hàng</div>
                                    <div className="content">{detail?.id}</div>
                                </div>
                            </Col>
                            <Col sm={24} xs={24} className="mt-12px">
                                <div className="prescriptions-detail__item">
                                    <div className="label">Địa chỉ nhận hàng</div>
                                    <div className="content content-multi">
                                    <span className="font-weight-bold"><span>{detail?.recipient?.name}</span>, <span style={{ cursor: 'pointer',color:'#0A9BE1' }} onClick={() => onPhone(detail?.recipient?.telephone)}>{detail?.recipient?.telephone}</span></span>
                                    <br />
                                        {/* <span className='font-weight-bold'>{`${detail?.recipient?.name}, ${detail?.recipient?.telephone}`}</span><br /> */}
                                        <span className='font-weight-normal'>{detail?.recipient?.address}</span>
                                    </div>
                                </div>
                            </Col>
                            {dataSource?.length > 0 ? (
                                <Col sm={24} xs={24} className="mt-16px">
                                    <div className="prescriptions-detail__item">
                                        <div className="label">Đơn thuốc</div>
                                        <Table
                                            dataSource={dataSource}
                                            columns={columns}
                                            pagination={false}
                                        />
                                    </div>
                                </Col>
                            ) : null}
                            {listImagesFind?.length > 0 ? (
                                <Col sm={24} xs={24} className="mt-16px">
                                    <div className="prescriptions-detail__item">
                                        <div className="label">Ảnh đơn thuốc</div>
                                        <Image.PreviewGroup>
                                            {listImagesFind?.map((item, index) => {
                                                return (
                                                    <Image width="30%" height="207px" src={item?.pathOriginal} />
                                                )
                                            })}
                                        </Image.PreviewGroup>
                                    </div>
                                </Col>
                            ) : null}
                            <Col sm={24} xs={24} className="mt-16px">
                                <div className="prescriptions-detail__item">
                                    <div className="label">Ghi chú</div>
                                    <div className="content">{detail?.metadata?.note ? detail?.metadata?.note : ""}</div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='prescriptions-detail__sub-info'>
                    <div className='sub-box'>
                            <div className='title'>Ngày tạo:</div>
                            <div className='phone'>{detail?.created ? moment(detail?.created).format("HH:mm:ss DD-MM-YYYY") : null}</div>
                        </div>
                        <div className='sub-box'>
                            <div className='title'>Người đặt:</div>
                            <div className='fullName'>{detailUser?.profileInfo?.personal?.fullName}</div>
                            <div className='username'>{detail.createdBy}</div>
                            <div className='phone' style={{ cursor: 'pointer' }} onClick={() => onPhone(detailUser?.profileInfo?.personal?.mobileNumber)}>{detailUser?.profileInfo?.personal?.mobileNumber}</div>
                        </div>
                        <div className='sub-box'>
                            <div className='title'>Nhà thuốc nhận đơn:</div>
                            <div className='name'>{detail.pharmacy?.name}</div>
                            <div className='address'>{detail.pharmacy?.contact?.address}</div>
                        </div>
                        {/* <div className='sub-box'>
                            <div className='title'>Lịch sử yêu cầu:</div>
                                <div className='history'>
                                    <Steps
                                        direction="vertical"
                                        current={detail?.events?.length > 0 ? detail?.events.length - 1 : 0}
                                        // current={1}
                                        className="history-steps"
                                        progressDot={customDot}
                                    >
                                        {(detail?.events || []).map((item, index) => (
                                            <Step
                                                key={item?.id + index}
                                                title={(item?.type && prescriptionEvents[item?.type]) || ""}
                                                description={
                                                    item?.lastModified ? moment(item?.lastModified).format(
                                                        "HH:mm DD/MM/YYYY"
                                                    ) : ""
                                                }
                                            />
                                        ))}
                                    </Steps>
                                </div>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }

    return (<>
        {isHasPermission ? (
            <div className='prescriptions-detail__container'>
                {renderHeader()}
                {renderBody()}
            </div>
        ) : <NotPermisstion heading="Chi tiết yêu cầu mua thuốc" />}
    </>)
} export default PrescriptionDetail;