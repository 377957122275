import { InputNumber } from "antd";
import styled from "styled-components";

export const InputWrapper = styled(InputNumber)`
  flex: 1 1;
  border-radius: 0 !important;
  min-height: 32px !important;
  /* &:last-child {
    border-radius: 0 4px 4px 0 !important;
  } */
`;
