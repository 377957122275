import axios from 'axios';
const WEB_API_KEY = "AIzaSyBdYm68fWgBgfRsvArkoSN4IT2TGuBIUvM";
export function renderAcademic(academicDegree) {
  if (academicDegree) {
    if (academicDegree?.value === "UNKNOW") {
      return "";
    }
    if (academicDegree?.value) {
      return `${academicDegree.value} `;
    } else if (typeof academicDegree === "string") {
      return `${academicDegree} `;
    }
  } else {
    return "";
  }
}
export const coppyTextToClipboard = async (text) => {
  const el = document.createElement('textarea');
  el.innerText = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}
export const combineUrlParams = (url = "", params = {}) => {
  const keys = Object.keys(params);
  const paramUrl = keys
    .reduce(
      (result, key) =>
        params[key] !== undefined && params[key] !== null && params[key] !== ""
          ? [...result, `${key}=${params[key]}`]
          : [...result],
      []
    )
    .join("&");
  return `${url}?${paramUrl}`;
};

export function generateUUID() {
  var d = new Date().getTime();//Timestamp
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16;//random number between 0 and 16
    if (d > 0) {//Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {//Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}
