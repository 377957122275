import { Popover } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import avatarDefault from "../../../../resources/images/sidebar/avatar-default.png";
import arrowIcon from "../../../../resources/images/sidebar/arrow.png";
import NotificationIcon from "./notification.svg";
import ArrowIcon from "./Arrow.svg";
import ItemNotificationIcon from './Notification'

import userProvider from "../../../../data-access/user-provider";
import { toast } from "react-toastify";
import { modalNotificationRef } from "../../Home";

function Profile({ handleChangePass }) {
  const currentUser = useSelector((state) => state.userApp.currentUser);
  const [isVisible, setIsVisible] = React.useState(false);

  const handlelogOut = () => {
    userProvider.logout()
      .then(res => {
        toast.success("Đăng xuất thành công");
        setTimeout(() => {
          const columnSetting = localStorage.getItem("columnSetting");
          const columnLeadSetting = localStorage.getItem("columnLeadSetting");

          localStorage.clear();
          if(columnSetting) {
            localStorage.setItem('columnSetting', columnSetting)
          }
          if(columnLeadSetting) {
            localStorage.setItem('columnLeadSetting', columnLeadSetting)
          }
          window.location.href = "/dang-nhap";
        }, 300)
      })
      .catch(err => {
        console.log(err);
        toast.error("Lỗi hệ thống")
      })
  };

  const content = () => {
    return (
      <>
        <div className="account-action" onClick={handleChangePass}>Đổi mật khẩu</div>
        <div className="account-action" onClick={handlelogOut}>
          Đăng xuất
        </div>
      </>
    );
  };

  const handleOpen = (visible) => {
    setIsVisible(visible);
  };

  const onShowNotification = (e) => {
    e.stopPropagation();
    modalNotificationRef.current.show()
  }

  return (
    <>
      <Popover
        placement="topLeft"
        title={""}
        content={content}
        trigger="click"
        overlayClassName="sidebar-footer-profiles"
        onVisibleChange={handleOpen}
      >
        <div className="profile-wrapper">
          <div className="profile-container">
            <div className="name" style={{ minWidth: 82}}>{currentUser?.name}</div>
            <div className="avatar">
              {/* <img src={avatar || avatarDefault} /> */}
              <img src={avatarDefault} />
            </div>
            <div className={`arrow ${isVisible ? "show" : ""}`}>
              <img src={ArrowIcon} />
            </div>
          </div>
          <div onClick={onShowNotification} className="notification-wrapper">
            <img className="notification" src={NotificationIcon} />
            <ItemNotificationIcon  />
          </div>
        </div>
      </Popover>
    </>
  );
}

export default Profile;
