import React from 'react';
import { Switch } from 'antd'
import styled from "styled-components";

const FormSwitch = (props) => {
    const {label} = props;
    const mode = props.mode || 'boolean'; // active
    const labelPosition = props.labelPosition || 'left'; // right
    return (
        <FormSwitchContainer>
            {!!label && labelPosition === 'left' ? (
                <>
                    {
                        mode === 'active' ? (
                            <FormSwitchText>{props.value?.toLowerCase() === 'active' ? "Hoạt động" : "Không hoạt động"}</FormSwitchText>
                        ) : (
                            <FormSwitchText>{props.label}</FormSwitchText>
                        )
                    }
                </>
            ) : null}

            <Switch
                checked={mode === 'active' ? (props?.value?.toLowerCase() === 'active') : props?.value}
                onChange={(event) => {
                    if (!props?.onChange) {
                        return;
                    }
                    if (mode === 'active') {
                        props.onChange(event ? 'active' : 'inactive');
                    } else {
                        props.onChange(event);
                    }
                }}
            />
            {!!label && labelPosition === 'right' ? (
                <>
                    {
                        mode === 'active' ? (
                            <FormSwitchText>{props.value?.toLowerCase() === 'active' ? "Hoạt động" : "Không hoạt động"}</FormSwitchText>
                        ) : (
                            <FormSwitchText>{props.label}</FormSwitchText>
                        )
                    }
                </>
            ) : null}
        </FormSwitchContainer>
    )
}
export default FormSwitch
const FormLabel = styled.label`
  display: block;
  font-weight: 500;
  font-size: 14px;
  color: #5D5D5D;
  text-align: left;
  height: fit-content;
  margin-bottom: 4px;
  position: relative;

  ${props => props.required ? `
  &:after {
    content: "*";
    color: red;
    margin-left: 3px;
    font-size: 13px;
  }
  ` : ``}
`;
const FormSwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const FormSwitchText = styled(FormLabel)`
  margin-bottom: 0;
`;

