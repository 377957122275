import { Table } from 'antd';
import styled from "styled-components";

export const TableStyled = styled(Table)`
    margin-top: 12px;
    width: 100%;
    background-color: #fafafa;
    border: 1px solid #DFE1E6;
    border-bottom: none;

    .table-header__actions {
        display: flex;
        align-items: center;
        justify-content: center !important;
    }

    .actions, .tool {
        display: flex;
        align-items: center;
        justify-content: center !important;
    }
`