import React, { useState, useEffect, useCallback } from "react";
import { InputWrapper } from "./styled";
import {
    SearchOutlined
} from "@ant-design/icons";
import { debounce } from "lodash";

function InputSearchNumber({ placeholder, onChange, defaultValue, className, disablePrefix, min, max }) {
    const [value, setValue] = useState(defaultValue);
    const debounceChangeData = useCallback(debounce(onChange, 300), []);

    useEffect(() => {
        if (defaultValue !== value) {
            setValue(defaultValue);
        }
    }, [defaultValue]);

    const onChangeValue = (number) => {
        const value = number;
        setValue(value);
        debounceChangeData(value)
    };

    return <InputWrapper
        placeholder={placeholder}
        onChange={onChangeValue}
        value={value}
        maxLength={50}
        style={{ width: "100%" }}
        prefix={disablePrefix ? null : <SearchOutlined />}
        className={className ? className : ""}
        min={min ? min : 0}
        max={max ? max : 99999999999}
    />;
}

export default InputSearchNumber;
