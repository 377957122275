import clientUtils from '@utils/client-utils';
import constants from '@resources/strings';

export default {
    getAuthorities() {
        return clientUtils.requestApiFix('get', constants.api.authorities).then((res) => res);
    },
    getRoles({ page = 0, size = 20, text = '' }) {
        const url = `${constants.api.roles}?page=${page}&size=${size}&text=${text}`;
        return clientUtils.requestApiFix('get', url).then((res) => res);
    },
    getPermisstions({ page = 0, size = 20 }) {
        const url = `${constants.api.permissions}?page=${page}&size=${size}`;
        return clientUtils.requestApiFix('get', url).then((res) => res);
    },
    createRole(body) {
        return clientUtils.requestApiFix('post', constants.api.roles, body).then((res) => res);
    },
    updateRole(id, body) {
        return clientUtils.requestApiFix('put', constants.api.roles + '/' + id, body).then((res) => res);
    },
    deleteRole(id) {
        return clientUtils.requestApiFix('delete', constants.api.roles + '/' + id).then((res) => res);
    },

    getAdmins({ page = 0, size = 20, username = '', name = '', locked = '', roleId = '', email }) {
        const url = `${constants.api.admins}?page=${page}&size=${size}${username ? `&username=${username}` : ''}${
            name ? `&name=${name}` : ''
        }${email ? `&email=${email}` : ''}${locked ? `&locked=${locked}` : ''}${roleId ? `&roleId=${roleId}` : ''}`;
        return clientUtils.requestApiFix('get', url).then((res) => res);
    },
    getAdmin(id) {
        const url = constants.api.admins + '/' + id;
        return clientUtils.requestApiFix('get', url).then((res) => res);
    },
    resetPassword(id) {
        const url = constants.api.admins + '/' + id + '/reset-password';
        return clientUtils.requestApiFix('put', url).then((res) => res);
    },
    createAdmin(body) {
        return clientUtils.requestApiFix('post', constants.api.admins, body).then((res) => res);
    },
    editAdmin(id, body) {
        return clientUtils.requestApiFix('put', constants.api.admins + '/' + id, body).then((res) => res);
    },
    lockedAccount(id) {
        return clientUtils.requestApiFix('put', constants.api.admins + '/' + id + '/locked').then((res) => res);
    },
    unlockedAccount(id) {
        return clientUtils.requestApiFix('put', constants.api.admins + '/' + id + '/unlocked').then((res) => res);
    },
    searchUser({ page = 0, size = 20, text = '', hospitalId = '' }) {
        const url = `${constants.api.search}?page=${page}&size=${size}${text ? `&text=${text ? text : ''}` : '&text='}${
            hospitalId ? `&hospitalId=${hospitalId}` : ''
        } `;
        return clientUtils.requestApiFix('get', url).then((res) => res);
    },
    selectUtils({ page = 0, size = 20 }) {
        const url = `${constants.api.filter}?page=${page}&size=${size}&active=true`;
        return clientUtils.requestApiFix('get', url).then((res) => res);
    },

    forgotPassword(body) {
        return new Promise((resolve, reject) => {
            clientUtils
                .requestApiFix('post', constants.api.forgetPassword, body)
                .then((x) => {
                    resolve(x);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    checkTokenForgot({ id }) {
        return new Promise((resolve, reject) => {
            clientUtils
                .requestApiFix('get', constants.api.forgetPassword + '/' + id)
                .then((x) => {
                    resolve(x);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },

    changePassword({ id }, body) {
        return new Promise((resolve, reject) => {
            clientUtils
                .requestApiFix('post', constants.api.changePasswordPassword.replace(':id', id), body)
                .then((x) => {
                    resolve(x);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
};
