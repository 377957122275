import { Select } from "antd";
import { uniqBy, debounce, union } from "lodash";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { nonAccentVietnamese } from "@utils/common";
import notificationProvider from "@data-access/notification-provider";
import { SelectCustom } from '../styled'
function SelectGetAPI({
    name,
    nameOption = (item) => item?.name || "",
    valueOption = (item) => item?.id || "",
    mode,
    defaultValue = mode === "multiple" ? [] : undefined,
    placeholder = "Chọn",
    pageDefault = 0,
    sizeDefault = 20,
    set,
    optionAll = false,
    allowClear = false,
    className,
    disabled = false,
    optionsDefault = [],
    searchName = name,
    keySearch,
    apiUrl
}) {
    const [options, setOptions] = useState([...optionsDefault]);
    const [page, setPage] = useState(pageDefault);
    const [total, setTotal] = useState(0);
    const [value, setValue] = useState();
    const [loading, setLoading] = useState(false);
    const timeoutRef = useRef();

    useEffect(() => {
        if (mode === 'multiple') {
            setOptions((prev) => {
                if (prev?.length) {
                    return uniqBy([...value, ...prev], (item) => {
                        return item.id;
                    });
                } else {
                    return value;
                }
            });
        }
    }, [value]);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);
    
    useEffect(() => {
        if(optionsDefault) {
            setOptions(optionsDefault)
        }
    }, [])

    const onScroll = (event) => {
        var target = event.target;
        if (
            !loading &&
            target.scrollTop + target.offsetHeight >= target.scrollHeight - 2
        ) {
            if (page < (pageDefault === 0 ? total - 1 : total)) {
                setPage(prev => {
                    getOptions({page: prev + 1})
                    return prev + 1
                });
            }
        }
    };

    const handleSearch = (value) => {
        let v = value;
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            if (v || v == "") {
                getOptions({ page: pageDefault, size: sizeDefault, [searchName]: v });
            }
        }, 300);
    };

    const getUrlEndpoint = (params) => {
        let search = params.name;
        let url = apiUrl.replace("{searchValue}", search ? search : "");
        if (url.includes("page={page}")) {
            url = url.replace("{page}", params.page);
        } else {
            if (url.includes("page=")) {
                url = url.replace(/page=\d+/, `page=${params.page}`);
            } else {
                url = `${url}&page=${params.page}`;
            }
        }
        if (url.includes("size={size}")) {
            url = url.replace("{size}", 20);
        } else {
            if (url.includes("size=")) {
                url = url.replace(/size=\d+/, `size=${20}`);
            } else {
                url = `${url}&size=${20}`;
            }
        }
        return url;
    }

    const getData = async (params) => {
        let urlEndPoint = getUrlEndpoint(params)
        try {
            const res = await notificationProvider.getEventRefs(urlEndPoint);
            if (res?.data?.data) {
                let data = res.data.data.map((h) => {
                    return { id: h.hospital.id, name: h.hospital.name };
                });
                let totalPages = 0;
                if (res.data.total === 0 && res.data.total > 20) {
                    totalPages = Math.ceil(res.data.total / 20);
                } else {
                    totalPages = Math.ceil(res.data.total / 20);
                }
                return {
                    data,
                    totalPages
                };
            }
            else {
                return {
                    data: [],
                    totalPages: 0
                }
            }
        }
        catch (err) {
            return {
                data: [],
                totalPage: 0,
            }
        }
    }

    const getOptions = async (params = {}) => {
        try {
            setLoading(true);
            const { data, totalPages } = await getData({
                page,
                size: sizeDefault,
                ...params,
            });
            setLoading(false);
            let optionsOld = page == pageDefault ? [] : [...options];
            let newOptions = uniqBy([...optionsOld, ...data], (item) => {
                return item.id;
            });
            if(defaultValue) {
                if(mode !== 'multiple') {
                    newOptions = newOptions?.filter(item => item?.id !== defaultValue?.id)
                    newOptions.unshift(defaultValue)
                } else {
                    newOptions = uniqBy([...value, ...newOptions], (item) => {
                        return item.id;
                    });
                }
            }
            if(optionsDefault?.length) {
                newOptions = [...optionsDefault, ...newOptions]
            }
            setOptions(newOptions);
            setTotal(totalPages);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleOnChange = (values, select) => {
        if(mode === 'multiple') {
            set(name, select?.map(item => ({
                name: item.optionValue?.name,
                id: item.optionValue?.id
            })) || null);
        } else {
            set(name, select?.optionValue || null);
        }
    };

    const handleFilter = (input, option) => {
        return nonAccentVietnamese((option.children || "").toLowerCase()).indexOf(nonAccentVietnamese(input || "").toLowerCase()) >= 0;
    };

    return (
        <SelectCustom
            style={{ width: "100%" }}
            onChange={set ? handleOnChange : undefined}
            showSearch={true}
            allowClear={allowClear}
            // defaultValue={defaultValue?.id}
            value={value ? mode === 'multiple' ? value.map((item) => item?.id) : value?.id : null}
            filterOption={handleFilter}
            onPopupScroll={onScroll}
            placeholder={placeholder}
            onSearch={handleSearch}
            onClear={handleSearch}
            mode={mode}
            className={className}
            onDropdownVisibleChange={(event) => {
                if(event) {
                    setPage(pageDefault)
                    getOptions({page: pageDefault})
                }
            }}
            disabled={disabled}
            dropdownClassName="select-infinity"
            maxTagCount='responsive'
            showArrow
        >
            {optionAll && (
                <SelectCustom.Option key="tat-ca" value="">
                    Tất cả
                </SelectCustom.Option>
            )}

            {options?.map((option, index) => (
                <Select.Option key={option?.id || index} value={valueOption(option)} optionValue={option}>
                    {nameOption(option) ? nameOption(option) : option.name}
                </Select.Option>
            ))}
            {loading && (
                <SelectCustom.Option key="loading" disabled>
                    Loading...
                </SelectCustom.Option>
            )}
        </SelectCustom>
    );
}

export default SelectGetAPI;
