import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Admin from "./sites/admin/Home";
import Login from "./sites/user/containners/account/Login";
import ChangePassword from "./sites/user/containners/account/ChangePassword";
// import Stuff from "./test/Stuff";

class Main extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="App">
        <Router>
          <div>
            <Route exact path="/" component={Login} />
            <Route exact path="/dang-nhap" component={Login} />
            <Route exact path="/forget-password/:id" component={ChangePassword} />
            {
              ["/admin", "/admin/:function", "/admin/:function/:id", "/admin/:function/:subfunction/:id"].map((item, index) =>
                <Route key={index} exact path={item} component={Admin} />
              )
            }
          </div>
        </Router>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userApp: state.userApp
  };
}

export default connect(mapStateToProps)(Main);