import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import './styles.scss';
import CheckIcon from './check.svg';

import { useInfinityList } from '@hooks/useInfinityList';
import notificationProvider from '@data-access/notification-provider';
import ItemNoti from './ItemNoti';
import { onReceiveMessage } from '@utils/notification';

const TABS = [
    {
        title: 'Tất cả',
        count: 0,
    },
    {
        title: 'Đơn hàng',
        count: 0,
        type: 'NEW_ORDER',
    },
    {
        title: 'Yêu cầu mua thuốc',
        count: 0,
        type: 'NEW_PERSCRIPTION',
    },
    {
        title: 'Đặt khám',
        count: 0,
        type: 'APPOINTMENT',
    },
    {
        title: 'Nhà thuốc',
        count: 0,
        type: 'NEW_PHARMACY',
    },
    {
        title: 'Thuốc',
        count: 0,
        type: 'NEW_MEDICINE',
    },
    {
        title: 'Mua gói gọi ngay',
        count: 0,
        type: 'INSTANT_CALL',
    },
    ,
    {
        title: 'Mua gói chat ngay',
        count: 0,
        type: 'CHAT',
    },
    {
        title: 'Vận hành',
        count: 0,
        type: 'OPERATE',
    },
];
const ModalNotification = forwardRef(({}, ref) => {
    const [isVisible, setIsVisible] = useState(false);
    const [tabs, setTabs] = useState(TABS);
    const [tabSelected, setTabSelected] = useState(TABS[0]);

    const [content, setContent] = useState([]);
    const saveContent = useRef();
    const getData = useCallback(
        async (page) => {
            try {
                const res = await notificationProvider.getListNotification({
                    page: page,
                    size: 10,
                    type: tabSelected?.type,
                });
                if (!Array.isArray(res?.content)) {
                    setContent([]);
                    return;
                }
                setContent((prev) => {
                    const newContent = page === 0 ? res?.content : prev.concat(res?.content);
                    saveContent.current = newContent;
                    return newContent;
                });
            } catch (error) {
                console.log(error, 'err');
            }
        },
        [tabSelected?.type],
    );

    const getCountNotification = useCallback(async () => {
        try {
            const res = await notificationProvider.getCountNotification();
            if (!Array.isArray(res)) {
                return;
            }
            // convert array result to object by key and return count notification by key
            const resObjectParse = {};
            let count = 0;
            res.forEach((item) => {
                resObjectParse[item?.type] = item?.count;
                // calculator total notification
                count += item?.count;
            });
            setTabs(() => {
                const newTabs = TABS.map((item, index) => {
                    if (index === 0) {
                        return { ...item, count: count };
                    }
                    return { ...item, count: resObjectParse[item?.type] || item?.count };
                });
                // dispatch an event to noti icon and update count noti in side bar
                const countNotification = new CustomEvent('countNotification', {
                    detail: { count: count },
                });
                document.dispatchEvent(countNotification);
                return newTabs;
            });
        } catch (error) {
            console.log(error, 'err');
        }
    }, []);

    const { setLastElement, onRefresh } = useInfinityList({ fetchData: getData, otherCondition: isVisible });

    const onHideModal = useCallback(() => {
        setIsVisible(false);
    }, []);

    const onShow = useCallback(() => {
        setIsVisible((prev) => !prev);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            show: onShow,
            hide: onHideModal,
        };
    });

    const onChangeTab = useCallback(
        (item, index) => (e) => {
            e?.stopPropagation && e.stopPropagation();
            setTabSelected(item);
            setContent([]);
            onRefresh();
        },
        [onRefresh],
    );

    const onSetRef = useCallback(
        (index) => (ref) => {
            if (index === saveContent.current?.length - 1) {
                setLastElement(ref);
            }
        },
        [],
    );

    const getNewData = useCallback(() => {
        onRefresh();
        getCountNotification();
    }, [onRefresh, getCountNotification]);

    const onViewAll = useCallback(
        async (e) => {
            try {
                e?.stopPropagation && e.stopPropagation();
                await notificationProvider.watchAllNotification();
                getNewData();
            } catch (error) {}
        },
        [getNewData],
    );

    const renderBody = useMemo(() => {
        return content.map((item, index) => <ItemNoti key={item?.id} item={item} index={index} onSetRef={onSetRef} />);
    }, [content]);

    useEffect(() => {
        const onListenerMessage = async () => {
            onReceiveMessage((message) => {
                if (message?.notification) getCountNotification();
            });
        };
        onListenerMessage();
        getCountNotification();
        window.addEventListener('focus', getNewData);
        return () => {
            window.removeEventListener('focus', getNewData);
        };
    }, [getCountNotification]);

    useEffect(() => {
        if (!isVisible) {
            setTabSelected(TABS[0]);
            onRefresh();
        }
    }, [isVisible, onRefresh]);

    if (!isVisible) {
        return <></>;
    }

    return (
        <div onClick={onHideModal} className="modal-notification">
            <div className="notification-container">
                <div className="header">
                    <div className="title">Thông báo</div>
                    <div onClick={onViewAll} className="btn-read">
                        <img src={CheckIcon} />
                        Đánh dấu là đã đọc
                    </div>
                </div>
                <div className="tabs">
                    {tabs.map((item, index) => (
                        <div
                            onClick={onChangeTab(item, index)}
                            key={item?.title}
                            className={`tab ${item?.title === tabSelected?.title && 'tab-selected'}`}
                        >
                            <span className="name">{item?.title}</span>
                            {!!item?.count && <div className="badge">{item?.count}</div>}
                        </div>
                    ))}
                </div>
                <div className="content">{renderBody}</div>
            </div>
        </div>
    );
});

export default ModalNotification;
